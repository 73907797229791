import { useState, useCallback, useMemo } from 'react';

import Config from '../config';

const useResidenceConfig = (isInterval = false) => {
  const [isResidenceConfigReady, setResidenceConfigReady] = useState(false);
  const { useUser, useResidenceConfigContext } = Config.getInstance().getConfiguration();
  const { user } = useUser();
  const {
    residenceConfigIsReady,
    residenceConfig,
    updateResidenceConfig,
    cleanResidenceConfig,
  } = useResidenceConfigContext();
  const {
    is_transfer,
    is_btc,
    is_usdt,
    is_usdc,
    is_recharge,
    is_payments,
    is_cashout,
    is_sent_vita_card,
    is_sent_available,
    referral_bonus_recharge,
    referral_bonus_amount,
    min_amount_exchange,
    min_amount_withdrawal,
    is_add_international_favorites,
  } = residenceConfig;

  const setResidenceConfig = useCallback(async (force = false) => {
    try {
      await updateResidenceConfig(user, force);
      setResidenceConfigReady(residenceConfigIsReady);
    } catch (error) { }
  }, [residenceConfig, residenceConfigIsReady]);

  const isCryptoAvailable = useMemo(() => {
    return (is_btc || is_usdt || is_usdc);
  }, [is_btc, is_usdt, is_usdc]);

  return {
    ...residenceConfig,
    min_amount_exchange: parseFloat(min_amount_exchange),
    min_amount_withdrawal: parseFloat(min_amount_withdrawal),
    isCashout: is_cashout,
    isTransfer: is_transfer,
    isBTC: is_btc,
    isUSDT: is_usdt,
    isUSDC: is_usdc,
    isCryptoAvailable,
    isRecharge: is_recharge,
    isPayments: is_payments,
    isSentVitaCard: is_sent_vita_card,
    isSentAvailable: is_sent_available,
    isAddInternationalFavorites: is_add_international_favorites,
    referral_bonus: {
      referral_bonus_recharge,
      referral_bonus_amount,
    },
    setResidenceConfig,
    isResidenceConfigReady,
    cleanResidenceConfig,
  };
};

export default useResidenceConfig;