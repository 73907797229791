import {ENDPOINTS} from '../../config/constants';
import {apiCall} from '../../middlewares';

const generateRechargeUrl = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.powwi.generateRechargeUrl,
    headers,
    data,
  });
};

const getBanksPSE = (headers) => {
  return apiCall({
    ...ENDPOINTS.powwi.getBanksPSE,
    headers,
  });
};

export default {
  generateRechargeUrl,
  getBanksPSE,
}