import {ENDPOINTS} from '../../config/constants';
import {apiCall} from '../../middlewares';

const getBusinessPaymentLink = (headers) => {
  return apiCall({
    ...ENDPOINTS.businessPaymentLink.getBusinessPaymentLink,
    headers,
  });
};

const updateBusinessPaymentLink = (headers, data) => {
  let formData = new FormData();

  Object.keys(data).forEach((key) => formData.append(key, data[key]));

  return apiCall({
    ...ENDPOINTS.businessPaymentLink.updateBusinessPaymentLink(data),
    data: formData,
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getChekoutInfo = (userId, publicCode) => {
  return apiCall({
    ...ENDPOINTS.businessPaymentLink.getChekoutInfo(userId, publicCode),
  });
};

const createPaymentOrder = (data, userId, publicCode) => {
  return apiCall({
    ...ENDPOINTS.businessPaymentLink.createPaymentOrder(
      userId,
      publicCode,
      data,
    ),
  });
};

const getStatusBussinessPaymentLink = (headers) => {
  return apiCall({
    ...ENDPOINTS.businessPaymentLink.getStatusBussinessPaymentLink,
    headers,
  });
};

const requestActiveServiceBusinessPaymentLink = (headers) => {
  return apiCall({
    ...ENDPOINTS.businessPaymentLink.requestServiceBusinessPaymentLink,
    headers,
  });
};

const createNewPaymentLink = async (userID, data, headers) => {
  return apiCall({
    ...ENDPOINTS.businessPaymentLink.createNewPaymentLink(userID, data),
    headers,
  });
};

const getPaymentLinkHistory = async (userID, data, headers) => {
  return apiCall({
    ...ENDPOINTS.businessPaymentLink.getPaymentLinkHistory(userID, data),
    headers,
  });
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  getBusinessPaymentLink,
  updateBusinessPaymentLink,
  getChekoutInfo,
  createPaymentOrder,
  getStatusBussinessPaymentLink,
  requestActiveServiceBusinessPaymentLink,
  createNewPaymentLink,
  getPaymentLinkHistory,
};
