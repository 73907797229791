import { useEffect, useRef, useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';

const useInterval = (callback, delay, isInterval = true) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useFocusEffect(
    useCallback(() => {
      const tick = () => {
        // savedCallback.current();
        callback();
      };

      if (delay && isInterval) {
        let id = setInterval(tick, delay);
        return () => {
          clearInterval(id);
        };
      }
    }, [isInterval, delay]),
  );

  /*useEffect(() => {
        const tick = () => {
            savedCallback.current();
        };

        if (delay !== null && isInterval) {
            let id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            };
        }
    }, [delay]);*/
};

export default useInterval;
