import { useEffect } from 'react';

import Config from '../config';

import { isBalancesUpdated } from '../utils';

const useUpdateUserBalances = (route) => {
  const { useUser } = Config.getInstance().getConfiguration();
  const { updateBalances } = useUser();
  let isFetchApi = false;

  useEffect(() => {
    if (isBalancesUpdated(route)) {
      (async () => {
        try {
          if (!isFetchApi) {
            isFetchApi = true;
            await updateBalances();
            isFetchApi = false;
          }
        } catch (error) {
          isFetchApi = false;
        }
      })();
    }
  }, [route]);

  return {};
};

export default useUpdateUserBalances;