import React, { createContext, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { ALL, WITHDRAWAL, TRANSFER, VITA_USER } from 'wallet/scenes/logged/settings/favoriteAccounts/utils.js';

import userServices from '../../v2/services/user';
import { onlyTwoWordsFromFullName, addEllipsis } from '../utils';
import { FREQUENT_ACCOUNTS_KEY, FREQUENT_ACCOUNTS_DEFAULT_VALUE } from './state';
import { userService } from '../services';

const prepareBankAccounts = (response, user) => {
  const options = response.map((option) => {
    try {
      let data = {
        id: option.id,
        ...option.attributes,
      };

      data.nameLabel = onlyTwoWordsFromFullName(user.first_name, user.last_name);
      data.bankLabel = `${addEllipsis(
        data.bank_name,
        8,
      )} - ${data.account_number.slice(-4)}`;
      data.nameLabelShort = addEllipsis(
        onlyTwoWordsFromFullName(user.first_name, user.last_name),
      );
      data.bankLabelShort = data.account_number.slice(-4);
      data.account_alias = data.alias;

      return data;
    } catch (error) {
      return null;
    }
  });

  return options;
};

const prepareBankAccount = (response, user) => {
  let data = {
    id: response.data.id,
    ...response.data.attributes,
  };

  data.nameLabel = onlyTwoWordsFromFullName(user.first_name, user.last_name);
  data.bankLabel = `${data.bank_name ? addEllipsis(
    data.bank_name,
    8,
  ) : 'N/A'} - ${data.account_number.slice(-4)}`;
  data.nameLabelShort = addEllipsis(
    onlyTwoWordsFromFullName(user.first_name, user.last_name),
  );
  data.bankLabelShort = data.account_number.slice(-4);
  data.account_alias = data.alias;

  return data;
};

const prepareAccounts = (response, isInternal) => {
  const options = response.map((option) => {
    try {
      let data = {
        id: option.id,
        ...option.attributes,
      };

      if (isInternal) {
        data.nameLabel = onlyTwoWordsFromFullName(
          data.first_name,
          data.last_name,
        );
        data.bankLabel = data.email;
        data.nameLabelShort = addEllipsis(
          onlyTwoWordsFromFullName(data.first_name, data.last_name),
        );
        data.bankLabelShort = addEllipsis(data.email);
      } else {
        if (data.company_name) {
          data.nameLabel = data.company_name;
          data.nameLabelShort = data.company_name;
        } else {
          data.nameLabel = onlyTwoWordsFromFullName(
            data.first_name,
            data.last_name,
          );
          data.nameLabelShort = addEllipsis(
            onlyTwoWordsFromFullName(data.first_name, data.last_name),
          );
        }
        const accountData = data.account_bank ? data.account_bank.slice(-4) : (data.account_number ? data.account_number.slice(-4) : data.associate_phone.slice(-4));
        data.bankLabel = `${data.bank_id_select ? addEllipsis(
          data.bank_id_select,
          8,
        ) : 'N/A'} - ${accountData}`;
        data.bankLabelShort = accountData;
      }

      if (data?.destination_iso_code?.toLowerCase() === 'br') {
        data[`account_bank__${data.account_type_bank}`] = data.account_bank;
        data.pix_key_type = data.account_type_bank;
        data.pix_key_type_select = data.account_type_bank_select;
      }

      return data;
    } catch (error) {
      return null;
    }
  });

  return options;
};

const prepareAccount = (response, isInternal) => {
  let data = {
    id: response.data.id,
    ...response.data.attributes,
  };

  if (isInternal) {
    data.nameLabel = onlyTwoWordsFromFullName(data.first_name, data.last_name);
    data.bankLabel = data.email;
    data.nameLabelShort = addEllipsis(
      onlyTwoWordsFromFullName(data.first_name, data.last_name),
    );
    data.bankLabelShort = addEllipsis(data.email);
  } else {
    if (data.company_name) {
      data.nameLabel = data.company_name;
      data.nameLabelShort = data.company_name;
    } else {
      data.nameLabel = onlyTwoWordsFromFullName(
        data.first_name,
        data.last_name,
      );
      data.nameLabelShort = addEllipsis(
        onlyTwoWordsFromFullName(data.first_name, data.last_name),
      );
    }

    const accountData = data.account_bank ? data.account_bank.slice(-4) : (data.account_number ? data.account_number.slice(-4) : data.associate_phone.slice(-4));
    data.bankLabel = `${data.bank_id_select ? addEllipsis(
      data.bank_id_select,
      8,
    ) : 'N/A'} - ${accountData}`;
    data.bankLabelShort = accountData;

    if (data?.destination_iso_code?.toLowerCase() === 'br') {
      data[`account_bank__${data.account_type_bank}`] = data.account_bank;
      data.pix_key_type = data.account_type_bank;
      data.pix_key_type_select = data.account_type_bank_select;
    }
  }

  return data;
};

const frequentAccounts = (useStorage) => {
  const FrequentAccountsContext = createContext();
  const { Provider } = FrequentAccountsContext;

  const FrequentAccountsProvider = (props) => {
    const [frequentAccounts, updateFrequentAccounts, hydrated] = useStorage(
      FREQUENT_ACCOUNTS_KEY,
      FREQUENT_ACCOUNTS_DEFAULT_VALUE,
    );

    const value = useMemo(
      () => ({
        frequentAccounts: frequentAccounts || FREQUENT_ACCOUNTS_DEFAULT_VALUE,
        updateFrequentAccounts,
        frequentAccountsIsReady: true,
      }),
      [frequentAccounts, updateFrequentAccounts, hydrated],
    );

    return <Provider value={value} {...props} />;
  };

  FrequentAccountsProvider.propTypes = {
    children: PropTypes.shape({}).isRequired,
  };

  const useFrequentAccounts = () => {
    const context = useContext(FrequentAccountsContext);

    if (!context) {
      throw new Error(
        'useFrequentAccounts must be used within a FrequentAccountsProvider',
      );
    }

    const { frequentAccounts, updateFrequentAccounts: update, ...rest } = context;

    const getBankAccounts = useCallback(
      async (user) => {
        const { bankAccounts } = frequentAccounts;
        const { accounts } = bankAccounts;
        return accounts;
      },
      [frequentAccounts],
    );

    const getFavoriteAccountsInternal = useCallback(
      async (headers) => {
        const { internalAccounts } = frequentAccounts;
        const { accounts } = internalAccounts;
        return accounts;
      },
      [frequentAccounts],
    );

    const getFavoriteAccountsInternational = useCallback(
      async (headers) => {
        const { internationalAccounts } = frequentAccounts;
        const { accounts } = internationalAccounts;
        return accounts;
      },
      [frequentAccounts],
    );

    // const updateFavoriteAccounts = useCallback(async (user, counter = 0) => {
    //   if (counter > 3) return;
    //   counter += 1;

    //   let payload = frequentAccounts;

    //   try {
    //     const response = await userServices.getBankAccount(user.headers);
    //     const newAccounts = prepareBankAccounts(response.data, user).filter(bank => bank !== null);

    //     payload = {
    //       ...payload,
    //       bankAccounts: {
    //         expiry: moment(),
    //         accounts: newAccounts,
    //       },
    //     };
    //   } catch {
    //     updateFavoriteAccounts(user, counter);
    //     return;
    //   }

    //   try {
    //     const response = await userServices.getFavoriteAccountsInternal(user.headers);
    //     const newAccounts = prepareAccounts(response.data, true).filter(account => account !== null);

    //     payload = {
    //       ...payload,
    //       internalAccounts: {
    //         expiry: moment(),
    //         accounts: newAccounts,
    //       },
    //     };
    //   } catch {
    //     updateFavoriteAccounts(user, counter);
    //     return;
    //   }

    //   try {
    //     const response = await userServices.getFavoriteAccountsInternational(user.headers);
    //     const newAccounts = prepareAccounts(response.data, false).filter(account => account !== null);

    //     payload = {
    //       ...payload,
    //       internationalAccounts: {
    //         expiry: moment(),
    //         accounts: newAccounts,
    //       },
    //     };

    //   } catch {
    //     updateFavoriteAccounts(user, counter);
    //     return;
    //   }

    //   update(payload)
    // }, [frequentAccounts, update]);

    const createFavoriteAccount = useCallback(
      async (headers, payload) => {
        try {
          const { account_type } = payload;
          const isInternal = account_type === 'internal';
          const response = await userServices.createFavoriteAccount(
            headers,
            payload,
          );
          const account = prepareAccount(response, isInternal);

          if (isInternal) {
            update({
              ...frequentAccounts,
              internalAccounts: {
                ...frequentAccounts.internalAccounts,
                accounts: [
                  { ...account },
                  ...frequentAccounts.internalAccounts.accounts,
                ],
              },
            });
          } else {
            update({
              ...frequentAccounts,
              internationalAccounts: {
                ...frequentAccounts.internationalAccounts,
                accounts: [
                  { ...account },
                  ...frequentAccounts.internationalAccounts.accounts,
                ],
              },
            });
          }

          return account;
        } catch (error) {
          return Promise.reject(error);
        }
      },
      [frequentAccounts, update],
    );

    const updateFavoriteAccount = useCallback(
      async (headers, idAccount, payload) => {
        try {
          const { account_type } = payload;
          const isInternal = account_type === 'internal';
          const response = await userService.updateFavoriteAccount(
            headers,
            idAccount,
            payload,
          );

          const account = prepareAccount(response, isInternal);

          let accounts = isInternal
            ? frequentAccounts.internalAccounts.accounts
            : frequentAccounts.internationalAccounts.accounts;

          accounts = accounts.map((accountItem) => {
            if (parseInt(accountItem.id) === parseInt(account.id)) {
              return account;
            }
            return accountItem;
          });

          if (isInternal) {
            update({
              ...frequentAccounts,
              internalAccounts: {
                ...frequentAccounts.internalAccounts,
                accounts: [...accounts],
              },
            });
          } else {
            update({
              ...frequentAccounts,
              internationalAccounts: {
                ...frequentAccounts.internationalAccounts,
                accounts: [...accounts],
              },
            });
          }

          return account;
        } catch (error) {
          return Promise.reject(error);
        }
      },
      [frequentAccounts, update],
    );

    const deleteFavoriteAccount = useCallback(
      async (headers, idAccount, isInternal = false) => {
        const accounts = isInternal
          ? frequentAccounts.internalAccounts.accounts
          : frequentAccounts.internationalAccounts.accounts;
        const accountBackUp = [...accounts]

        try {

          let index = -1;
          accounts.every((account, i) => {
            if (parseInt(account.id) === parseInt(idAccount)) {
              index = i;
            }

            return !(index === i);
          });

          if (index !== -1) {
            accounts.splice(index, 1);
          }

          if (isInternal) {
            update({
              ...frequentAccounts,
              internalAccounts: {
                ...frequentAccounts.internalAccounts,
                accounts: [...accounts],
              },
            });
          } else {
            update({
              ...frequentAccounts,
              internationalAccounts: {
                ...frequentAccounts.internationalAccounts,
                accounts: [...accounts],
              },
            });
          }
          await userServices.deleteFavoriteAccount(headers, idAccount);

          return accounts;
        } catch (error) {
          if (isInternal) {
            update({
              ...frequentAccounts,
              internalAccounts: {
                ...frequentAccounts.internalAccounts,
                accounts: accountBackUp,
              },
            });
          } else {
            update({
              ...frequentAccounts,
              internationalAccounts: {
                ...frequentAccounts.internationalAccounts,
                accounts: accountBackUp,
              },
            });
          }
          return Promise.reject(error);
        }
      },
      [frequentAccounts, update],
    );

    const createBankAccount = useCallback(
      async (user, data) => {
        try {
          const response = await userServices.createBankAccount(
            user.headers,
            data,
          );
          const account = prepareBankAccount(response, user);

          update({
            ...frequentAccounts,
            bankAccounts: {
              ...frequentAccounts.bankAccounts,
              accounts: [
                { ...account },
                ...frequentAccounts.bankAccounts.accounts,
              ],
            },
          });

          return account;
        } catch (error) {
          return Promise.reject(error);
        }
      },
      [frequentAccounts, update],
    );

    const updateBankAccount = useCallback(
      async (user, idAccount, data) => {
        try {
          const response = await userServices.updateBankAccount(
            user.headers,
            idAccount,
            data,
          );
          const account = prepareBankAccount(response, user);
          let accounts = frequentAccounts.bankAccounts.accounts;

          accounts = accounts.map((accountItem) => {
            if (parseInt(accountItem.id) === parseInt(account.id)) {
              return account;
            }
            return accountItem;
          });

          update({
            ...frequentAccounts,
            bankAccounts: {
              ...frequentAccounts.bankAccounts,
              accounts: [...accounts],
            },
          });

          return account;
        } catch (error) {
          return Promise.reject(error);
        }
      },
      [frequentAccounts, update],
    );

    const deleteBankAccount = useCallback(
      async (headers, idAccount) => {
        const accounts = frequentAccounts.bankAccounts.accounts;
        const accountsBackUp = [...accounts]
        try {
          let index = -1;
          accounts.every((account, i) => {
            if (parseInt(account.id) === parseInt(idAccount)) {
              index = i;
            }

            return !(index === i);
          });

          if (index !== -1) {
            accounts.splice(index, 1);
          }

          update({
            ...frequentAccounts,
            bankAccounts: {
              ...frequentAccounts.bankAccounts,
              accounts: [...accounts],
            },
          });
          await userServices.deleteBankAccount(headers, idAccount);

          return accounts;
        } catch (error) {
          update({
            ...frequentAccounts,
            bankAccounts: {
              ...frequentAccounts.bankAccounts,
              accounts: accountsBackUp,
            },
          });
          return Promise.reject(error);
        }
      },
      [frequentAccounts, update],
    );

    const cleanFrequentAccounts = () => {
      update(FREQUENT_ACCOUNTS_DEFAULT_VALUE);
    };

    const getListFavoriteAccounts = useCallback(
      async (user, params) => {

        let payload = frequentAccounts;

        try {
          const response = await userServices.getListFavoriteAccounts(user.headers, params);
          switch (params.type) {
            case WITHDRAWAL:
              return {
                favorites: prepareBankAccounts(response.accounts.data, user).filter(bank => bank !== null),
                total: response.total,
              };
            case VITA_USER:
              const listFavorites = prepareAccounts(response.accounts.data, true).filter(account => account !== null);

              update({
                ...payload,
                bankAccounts: { accounts: listFavorites },
              });

              return {
                favorites: listFavorites,
                total: response.total,
              };
            case TRANSFER:
              return {

                favorites: prepareAccounts(response.accounts.data, false).filter(account => account !== null),
                total: response.total,
              };
            case ALL:
              const vitaFavoritesAccounts = prepareAccounts(response.vita_users.accounts.data || [], true).filter(account => account !== null);
              const internationalFavoritesAccounts = prepareAccounts(response.transfer.accounts.data || [], false).filter(account => account !== null);
              const bankFavoritesAccounts = prepareBankAccounts(response.withdrawals.accounts.data || [], user).filter(bank => bank !== null);

              update({
                bankAccounts: { accounts: bankFavoritesAccounts },
                internalAccounts: { accounts: vitaFavoritesAccounts },
                internationalAccounts: { accounts: internationalFavoritesAccounts },
              });

            return {
              vitaFavoritesAccounts,
              internationalFavoritesAccounts,
              bankFavoritesAccounts,
              totalVitaFavorites: response.vita_users.total,
              totalInternationalFavorites: response.transfer.total,
              totalBankFavorites: response.withdrawals.total,
              total: response.vita_users.total + response.transfer.total + response.withdrawals.total,
            };
            default:
                return []
        }
      } catch  {}
      }
    , [update]);

    const getFrecuentAccount = useCallback(
      async (user, params) => {
        try {
          const response = await userServices.getFrecuentAccount(user.headers, params);

          switch (params.type) {
            case WITHDRAWAL:
              return (prepareBankAccounts([response.data], user).filter(bank => bank !== null))[0];
            case VITA_USER:
              return (prepareAccounts([response.data], true).filter(account => account !== null))[0];
            case TRANSFER:
              return (prepareAccounts([response.data], false).filter(account => account !== null))[0];
            default:
              return []
          }
        } catch (error) {
          return Promise.reject(error);
        }
      }
    , []);

    const searchFrequentAccount = useCallback(
      async (user, params) => {
        try {
          const response = await userServices.searchFrequentAccount(user.headers, params);

          switch (params.type) {
            case WITHDRAWAL:
              return {
                favorites: prepareBankAccounts(response.accounts.data, user).filter(bank => bank !== null),
                total: response.total,
              };
            case VITA_USER:
              return {
                favorites: prepareAccounts(response.accounts.data, true).filter(account => account !== null),
                total: response.total,
              };
            case TRANSFER:
              return {
                favorites: prepareAccounts(response.accounts.data, false).filter(account => account !== null),
                total: response.total,
              };
            default:
              return []
          }
        } catch (error) {
          return Promise.reject(error);
        }
      }
    , []);

    return {
      ...rest,
      frequentAccounts,
      cleanFrequentAccounts,
      // updateFavoriteAccounts,
      getFavoriteAccountsInternal,
      getFavoriteAccountsInternational,
      createFavoriteAccount,
      updateFavoriteAccount,
      deleteFavoriteAccount,
      getBankAccounts,
      createBankAccount,
      updateBankAccount,
      deleteBankAccount,
      getListFavoriteAccounts,
      getFrecuentAccount,
      searchFrequentAccount,
    };
  };

  return {
    FrequentAccountsProvider,
    useFrequentAccounts,
  };
};

export default frequentAccounts;
