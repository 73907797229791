import { useState, useEffect } from 'react';

import Config from '../config';

const PRICE_BASE = {
  usdt_buy: 1,
  usdt_sell: 1,
  usdc_buy: 1,
  usdc_sell: 1,
  btc_min_total_send_external: 0.0004,
  btc_fee_send_external: 0.0001,
  usdt_fee_send_external: 0,
  usdc_fee_send_external: 0,
  btc_buy: 1,
  btc_sell: 1,
};

const pricesInitials = {
  clp: PRICE_BASE,
  mxn: PRICE_BASE,
  ars: PRICE_BASE,
  usd: PRICE_BASE,
  cop: PRICE_BASE,
  btc: PRICE_BASE,
  usdc: PRICE_BASE,
  usdt: PRICE_BASE,
};

const usePrices = (isInterval = true) => {
  const [hookPrices, setHookPrices] = useState(pricesInitials);
  const [isReadyPrices, setReadyPrices] = useState(false);
  const [errorFetchMessage, setErrorFetchMessage] = useState('');
  const { usePricesContext } = Config.getInstance().getConfiguration();
  const {
    pricesIsReady,
    prices: {
      prices: { prices, expiry },
      fetchErrorPrices,
    },
    cleanPrices,
  } = usePricesContext();

  useEffect(() => {
    if (pricesIsReady && Object.keys(prices).length > 0) {
      setHookPrices({ ...prices, expiry });
      setErrorFetchMessage(fetchErrorPrices);
      setReadyPrices(true);
    } else {
      setReadyPrices(false);
    }
  }, [prices, pricesIsReady, fetchErrorPrices, expiry]);

  return {
    prices: hookPrices,
    errorFetch: errorFetchMessage,
    isReadyPrices,
    cleanPrices,
  };
};

export default usePrices;
