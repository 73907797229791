import React from 'react';
import PropTypes from 'prop-types';
import * as Animatable from 'react-native-animatable';
import {View, Image} from 'react-native';

import useWindow from 'wallet/hooks/useWindow';
import TextField, {MAX_SIZE, EXTRA_NORMAL_SIZE} from '../TextField';

import {Assets} from 'alejandra/resources';
import {Assets as AssetsW, flexCenter} from '../../../resources';

import styles from './styles';

const Loading = (props) => {
  const {isTransparent, containerStyle, isCenter, extraStyle, isHelperContent} = props;
  const {isWeb} = useWindow();
  
  return (
    // <Modal transparent>
      <View
        style={
          [
            containerStyle 
              ? containerStyle 
              : isTransparent 
              ? styles.transparent_container 
              : styles.container,
            isCenter && {...flexCenter, paddingTop: 0},
            extraStyle,
            {
              zIndex: 9999999,
              elevation: 9999999,
            }
          ]
        }
      >
        <View style={isWeb ? styles.image_content_animated : styles.image_content}>
          {/* <Image style={styles.image} source={Assets.images.vitaLoader} /> */}
          <Animatable.View
            animation="swing"
            iterationCount="infinite"
            direction="alternate"
            delay={200}
          >
            <Image style={isWeb ? styles.imageAnimated : styles.image} source={isWeb ? AssetsW.images.vitaLoaderAnimated : Assets.images.vitaLoader} />
          </Animatable.View>
        </View>
        {
          isHelperContent && (
            <View style={styles.content_helper}>
              <Image
                source={AssetsW.images.peopleLoading}
                style={styles.people_loading}
              />
              <TextField
                text={'¡Estamos procesando tu solicitud!'}
                style={styles.title_helper}
                type={MAX_SIZE}
                isTitle
                isCenter
              />
              <TextField
                text={'Este proceso puede tardar algunos\nminutos.'}
                style={styles.text_helper}
                type={EXTRA_NORMAL_SIZE}
                isCenter
              />
            </View>
          )
        }
      </View>
    // </Modal>
  );
};

Loading.propTypes = {
  isTransparent: PropTypes.bool,
  isCenter: PropTypes.bool,
  isHelperContent: PropTypes.bool,
  containerStyle: PropTypes.any,
};

Loading.defaultProps = {
  isTransparent: false,
  isCenter: false,
  isHelperContent: false,
  containerStyle: null,
};

export default Loading;
