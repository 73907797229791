import { DUMDEE_CONSTANTS } from "dumdee";
import {
  ENDPOINTS,
  ENV,
  MOBILE_VERSION,
  IS_MOBILE,
  RECAPTCHA,
  CUSTOMERIO,
  ENCRYPTED_STORAGE,
} from "./env";

const {
  API,
  DEEP_LINKING,
  HOME_LINKING,
  GOOGLE_SIGNIN_CONFIG,
  BLOCKCHAIN,
  FIREBASE,
  HOTJAR,
  INTERCOM,
  DLOCAL,
} = ENDPOINTS;
const { QA_ENVIRONMENT, PROD_ENVIRONMENT, LOCAL_ENVIRONMENT, STAGE_ENVIRONMENT, DEV_ENVIRONMENT } = DUMDEE_CONSTANTS;

export const API_URL = API[ENV];
export const DEEP_LINKING_PREFIXE = DEEP_LINKING[ENV];
export const HOME_LINKING_PREFIXE = HOME_LINKING[ENV];
export const GOOGLE_SIGNIN_CONFIGURATION = GOOGLE_SIGNIN_CONFIG[ENV];
export const FIREBASE_CONFIGURATION = FIREBASE[ENV];

export const currenciesReceive = ["CLP", "USD", "BTC"];

export const TRUST_PILOT_URL = "https://es.trustpilot.com/review/vitawallet.io";

export const GOOGLE_PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.vita_wallet";

export const APP_STORE_URL =
  "https://apps.apple.com/cl/app/vita-wallet/id1486999955";

export const ALEJANDRA_VERSION = MOBILE_VERSION;

export const IS_MOBILE_APP = IS_MOBILE;

export const BLOCKCHAIN_ORDERS_LIVE_URL = BLOCKCHAIN[ENV].orders_live_url;

export const ENVIRONMENT =
  ENV === 'qa'
    ? QA_ENVIRONMENT
    : ENV === 'local'
      ? LOCAL_ENVIRONMENT
      : ENV === 'dev'
        ? DEV_ENVIRONMENT
        : ENV === 'stage'
          ? STAGE_ENVIRONMENT
          : PROD_ENVIRONMENT;

export const GOOGLE_RECAPTCHA = RECAPTCHA[ENV];

export const HOTJAR_HJID = HOTJAR[ENV].hjid;

export const HOTJAR_HJSV = HOTJAR[ENV].hjsv;

export const IS_PIN_AUTH = false;

export const IS_NEW_VERSION = false;

export const INTERCOM_CONFIG = INTERCOM[ENV];

export const CIO_SITE_ID = CUSTOMERIO[ENV].cio_site_id;

export const CIO_API_KEY = CUSTOMERIO[ENV].cio_api_key;

export const DLOCAL_URL = DLOCAL[ENV].url;

export const DLOCAL_KEY = DLOCAL[ENV].key;

export const URL_TERMS_AND_CONDITIONS_PAYMENT_LINK = "https://vitawallet.io/terminos-y-condiciones-enlances-de-pago";

export const ENCRYPTED_STORAGE_ENV = ENCRYPTED_STORAGE[ENV].is;

export const ENCRYPT_KEY = ENCRYPTED_STORAGE[ENV].key;
