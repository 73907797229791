import {ENDPOINTS} from '../../config/constants';
import {apiCall} from '../../middlewares';

const getCouponsUser = (headers, status) => {
  return apiCall({
    ...ENDPOINTS.coupons.getCoupons(status),
    headers,
  });
};

const searchCouponsUser = (headers, identifier) => {
  return apiCall({
    ...ENDPOINTS.coupons.searchCoupon(identifier),
    headers,
  });
};

const activeCouponUser = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.coupons.activeCoupon(data),
    headers,
  });
};

const cancelCouponUser = (headers, coupon_user_id) => {
  return apiCall({
    ...ENDPOINTS.coupons.cancelCoupon(coupon_user_id),
    headers,
  });
};

const getCouponTransactionUser = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.coupons.getCouponTransaction(data),
    headers,
  });
};

export default {
  getCouponsUser,
  searchCouponsUser,
  activeCouponUser,
  cancelCouponUser,
  getCouponTransactionUser,
};
