import {ENDPOINTS} from '../../config/constants';
import {apiCall} from '../../middlewares';

const getFormByType = (headers, type) => {
  return apiCall({
    ...ENDPOINTS.dynamic_forms.get_form_by_type(type),
    headers,
  });
};

const getSelectOptions = (headers, form_field_id, dependent_option) => {
  return apiCall({
    ...ENDPOINTS.dynamic_forms.get_select_options(form_field_id, dependent_option),
    headers,
  });
};

export default {
  getFormByType,
  getSelectOptions,
}