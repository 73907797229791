import moment from 'moment';

/**
 **  CONTEXT KEY
 */

export const SETTINGS_KEY = 'SD';
export const BANKS_KEY = 'BD';
export const BANNERS_KEY = 'BAD';
export const ALERTS_KEY = 'ALD';
export const RULES_KEY = 'RD';
export const SESSION_KEY = 'SED';
export const SIDEBAR_KEY = 'SID';
export const PRICES_KEY = 'PRID';
export const PRICES_TRANSFER_KEY = 'PTRD';
export const FREQUENT_ACCOUNTS_KEY = 'FAD';
export const USER_KEY = 'UD';
export const RESIDENCE_CONFIG_KEY = 'RCD';
export const VERSIONS_KEY = 'VD';
export const VITA_COUNTRIES_KEY = 'VCD';
export const NOTIFICATION_KEY = 'ND';

/**
 **  CONTEXT STATES
 */

export const VERSIONS_DEFAULT_VALUE = {
  'x-settings': '0',
  'x-residence-config': '0',
  'x-rules': '0',
  'x-favorite-accounts': '0',
};

export const VITA_COUNTRIES_DEFAULT_VALUE = {
  countries: [],
  expiry: moment().diff(10, 'hours'),
};

export const RESIDENCE_CONFIG_DEFAULT_VALUE = {
  name: 'Colombia',
  currency: 'cop',
  local_currency: 'COP',
  currency_iso_code: 'COP',
  iso_code: 'CO',
  is_cashout: true,
  is_register: true,
  is_birth: true,
  is_transfer: true,
  valid_until: moment().add(1,'m').toISOString(),
  is_btc: false,
  is_usdt: false,
  is_recharge: true,
  currency_name: 'Peso colombiano',
  currency_plural_name: 'Pesos colombianos',
  flag_url: 'https://vita-wallet-api-qa-2.appspot.com/mailers/flags/CO.png',
  society: null,
  currency_decimals: 0,
  referral_bonus_recharge: 0,
  referral_bonus_amount: 0,
  is_sent_vita_card: false,
  size_of_digits_for_sms: 6,
  is_payments: false,
  is_sent_receive: true,
  is_sent_available: true,
  expiry: moment().diff(10, 'hours'),
  list_notification_manager: [],
};

export const NOTIFICATION_DEFAULT_VALUE = {
  list_notification_manager: [],
};

export const FREQUENT_ACCOUNTS_DEFAULT_VALUE = {
  internalAccounts: {
    expiry: moment().diff(10, 'hours'),
    accounts: [],
  },
  internationalAccounts: {
    expiry: moment().diff(10, 'hours'),
    accounts: [],
  },
  bankAccounts: {
    expiry: moment().diff(10, 'hours'),
    accounts: [],
  },
};

export const SETTINGS_DEFAULT_VALUE = {
  pushNotifications: [],
};

export const SIDEBAR_DEFAULT_VALUE = {
  isCollapsedMenu: false,
  isSettingsMenu: false,
};

export const BANKS_DEFAULT_VALUE = {
  banksProfile: {
    banks: [],
  },
};

export const BANNERS_DEFAULT_VALUE = {
  banners: {
    referrals: true,
    profile_verification: true,
    profile_verification_popup: true,
    email_confirmed: false,
    sessionExpired: true,
    isWiffiError: true,
    coupon: false,
    couponMoment: '',
    general: true,
  },
};

export const ALERTS_DEFAULT_VALUE = {
  coupons: {
    alertCoupon: true,
    counter: 0,
  },
};

export const RULES_DEFAULT_VALUE = {
  transferRules: {
    expiry: moment().diff(10, 'hours'),
    rules: [],
  },
};

// export const PRICES_EXPIRY_TIME_VALUE = {
//   bitcoin: moment().diff(10, 'hours'),
//   transfer: moment().diff(10, 'hours'),
// };

export const PRICES_DEFAULT_VALUE = {
  prices: {},
  fetchErrorPrices: '',
  expiry: moment().diff(10, 'hours'),
};

export const PRICES_TRANSFER_DEFAULT_VALUE = {
  transferPrices: {
    prices: {},
  },
  fetchError: '',
  expiry: moment().diff(10, 'hours'),
};

export const SESSION_DEFAULT_VALUE = {
  expirySession: null,
  expiry: null,
};

export const USER_DEFAULT_VALUE = null;
