import {StyleSheet} from 'react-native';

import {Colors, ShadowProperties, flexCenter} from 'wallet/resources';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 999999,
    elevation: 999999,
    display: 'flex',
    paddingTop: 100,
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
  transparent_container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 999999,
    elevation: 999999,
    display: 'flex',
    paddingTop: 100,
    alignItems: 'center',
  },
  image_content: {
    ...ShadowProperties,
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    backgroundColor: Colors.C_FFFFFF,
    paddingTop: 5,
  },
  image_content_animated: {
    ...ShadowProperties,
    borderRadius: 50,
  },
  image: {
    width: 25,
    height: 25,
  },
  imageAnimated: {
    width: 50,
    height: 50,
  },
  content_helper: {
    ...flexCenter,
    marginTop: 50,
  },
  title_helper: {
    color: Colors.C_333333,
    marginTop: 12,
  },
  text_helper: {
    color: Colors.C_333333,
    marginTop: 16,
  },
  people_loading: {
    width: 230,
    height: 230,
    resizeMode: 'contain',
  }
});

export default styles;
