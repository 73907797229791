import {Platform} from 'react-native';

// import {width, height} from '../hooks/useWindowsSize';

import wallpaperLogin from './assets/wallpaper_login.png';
import notEye from './assets/not_eye.png';
import sucessfulPayment from './assets/succesful_payment.png';
import checkError from './assets/check_error.png';
import eye from './assets/eye.png';
import unCheck from './assets/un_check.png';
import check from './assets/check.png';
import woman_bubble from './assets/woman_bubble.png';
import vitaFullColorLogo from './assets/vita_logo.png';
import googleLogo from './assets/google.png';
import errorScreen from './assets/error_screen.png';
import registerScreen from './assets/register_screen.png';
import vitaLoader from './assets/vita_loader.png';
import okKeyboard from './assets/ok_keyboard.png';
import bell from './assets/bell.png';
import back from './assets/back.png';
import home from './assets/home.png';
import enviar from './assets/enviar.png';
import recargar from './assets/recargar.png';
import recibir from './assets/recibir.png';
import dollar from './assets/dollar.png';
import down from './assets/down.png';
import whiteDollar from './assets/white_dollar.png';
import closeButton from './assets/close.png';
import blackBitcoin from './assets/black_btc.png';
import bubbleEmpty from './assets/elipse_empty.png';
import succesfulVerificationSent from './assets/succesful_verification_sent.png';
import verificationWait from './assets/verification_wait.png';
import miniatureWithoutProfile from './assets/users/userPhotoWithoutProfile.png';
import bubbleActiveGreen from './assets/elipse_active_green.png';
import pinRequest from './assets/pin_request.png';
import pinRecover from './assets/succesful_pin_recover.png';
import activeVerticalLine from './assets/active_line.png';
import inActiveVerticalLine from './assets/inactive_line.png';
import bubbleInProgress from './assets/elipse_progress.png';
import emptyTransactions from './assets/empty_transactions.png';
import sendButton from './assets/send_button.png';
import link from './assets/link.png';
import vitaCard from './assets/vita_card.png';
import webpay from './assets/webpay.png';
import visa from './assets/Visa.png';
import masterCard from './assets/MasterCard.png';
import daleCard from './assets/dale_card.png';
import successfulRecharge from './assets/successful_recharge.png';
import activeBell from './assets/active_bell.png';
import successfulWithdrawal from './assets/successful_withdrawal.png';
import successfulSent from './assets/successful_sent.png';
import vita from './assets/vita.png';
import serviceNotAvailable from './assets/service_not_available.png';
import dump from './assets/dump.png';
import bankAccount from './assets/bank_account.png';
import bank from './assets/bank.png';
import clock from './assets/clock.png';
import checkSmall from './assets/check_small.png';
import sad from './assets/sad.png';
import starOn from './assets/star_on.png';
import starOff from './assets/star_off.png';
import zapOff from './assets/zap.png';
import zapOn from './assets/zap_on.png';
import coffeOff from './assets/coffee_off.png';
import coffeOn from './assets/coffee.png';
import dollarOn from './assets/dollar_on.png';
import smilleOn from './assets/smile_on.png';
import smilleOff from './assets/smile_off.png';
import Sol from './assets/S.png';
import paymentWallpaper from './assets/wallpaper_payment.png';
import refresh from './assets/refresh.png';
import money from './assets/money.png';
import bank_mobile from './assets/bank_mobile.png';
import favoriteAccountFiat from './assets/favoriteAccountFiatButton.png';
import editButton from './assets/edit.png';
import addButton from './assets/add.png';
import arrowRight from './assets/arrow-right.png';
import SolOn from './assets/SolOn.png';

export const Assets = {
  images: {
    SolOn,
    arrowRight,
    favoriteAccountFiat,
    editButton,
    addButton,
    money,
    bank_mobile,
    refresh,
    users: {
      miniatureWithoutProfile,
    },
    sad,
    woman_bubble,
    Sol,
    smilleOff,
    smilleOn,
    dollarOn,
    paymentWallpaper,
    zapOn,
    zapOff,
    coffeOff,
    coffeOn,
    clock,
    checkSmall,
    wallpaperLogin,
    sendButton,
    dump,
    starOff,
    starOn,
    daleCard,
    activeBell,
    bankAccount,
    bank,
    webpay,
    visa,
    successfulSent,
    link,
    successfulRecharge,
    serviceNotAvailable,
    masterCard,
    vitaCard,
    successfulWithdrawal,
    emptyTransactions,
    inActiveVerticalLine,
    activeVerticalLine,
    bubbleInProgress,
    bubbleEmpty,
    pinRecover,
    pinRequest,
    verificationWait,
    blackBitcoin,
    closeButton,
    whiteDollar,
    down,
    bell,
    vita,
    dollar,
    bubbleActiveGreen,
    recibir,
    recargar,
    sucessfulPayment,
    enviar,
    home,
    back,
    succesfulVerificationSent,
    okKeyboard,
    vitaLoader,
    registerScreen,
    errorScreen,
    checkError,
    notEye,
    eye,
    unCheck,
    check,
    vitaFullColorLogo,
    googleLogo,
  },
};

export const Colors = {
  linear_white_transparent_footer: [
    'rgba(255,255,255,0)',
    'rgba(255,255,255,0.1)',
    'rgba(255,255,255,0.6)',
    'rgba(255,255,255,0.6)',
    'rgba(255,255,255,0.6)',
    'rgba(255,255,255,0.8)',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
  ],
  linear_white_transparent: [
    'rgba(255,255,255,0)',
    'rgba(255,255,255,0.2)',
    'rgba(255,255,255,0.8)',
    '#FFFFFF',
  ],
  linear_white_transparent_full: [
    'rgba(255,255,255,0)',
    'rgba(255,255,255,0.8)',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
  ],
  linear_gradient_qr_code_inverse: ['#005FEE', '#00BB8D'],
  linear_white: ['#FFFFFF', '#FFFFFF'],
  linear_gradient_qr_code: ['#00BB8D', '#005FEE'],
  linear_gradient_orange: ['#F2994A', '#F2994A'],
  linear_gradient_gray: ['#F2F2F2', '#F2F2F2'],
  yellow_light: 'rgb(255,251,244)',
  yellow_light_2: 'rgba(222, 214, 33, 0.16)',
  gray_light: '#F2F2F2',
  CF2F2F2: '#F2F2F2',
  blue_CTA: '#005FEE',
  white: '#FFFFFF',
  black: '#333333',
  green_CTA: '#00BB8D',
  green_CTA_alpha16: 'rgba(0,187,141,0.16)',
  green_CTA_3: '#14AD92',
  gray_hard: '#BDBDBD',
  gray_dark_2: '#6c6c6c',
  gray_dark: '#333333',
  red: '#FF0000',
  red_light: 'rgba(221, 83, 53, 0.16)',
  orange: '#F2994A',
  gray_light_2: '#4F4F4F',
  gray_light_3: '#777777',
  gray_light_4: '#828282',
  gray_ligth_5: '#FCFDFF',
  gray_light_7: 'rgba(28, 57, 101, 0.4)',
  green_olive: 'rgba(92, 205, 73, 0.16)',
  green_dark: '#27AE10',
  yellow_dark: '#AFA90B',
  red_dark: '#DD5335',
};

// box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.12);
export const ShadowProperties =
  Platform.OS === 'ios'
    ? {
        shadowColor: '#000',
        shadowOffset: {
          width: 2,
          height: 4,
        },
        shadowOpacity: 0.12,
        shadowRadius: 16,
        elevation: 10,
        overflow: 'visible',
      }
    : {
        shadowColor: '#000',
        shadowOffset: {
          width: 2,
          height: 4,
        },
        shadowOpacity: 0.12,
        shadowRadius: 16,
        elevation: 15,
      };

export const Fonts = {
  regular: 'Lato-Regular',
  black: 'Lato-Black',
  bold: 'Lato-Bold',
  medium: 'Lato-Medium',
};

// const pixelRatio = PixelRatio.get();
// const deviceHeight = height;
// const deviceWidth = width;

const adjustFontSize = (size) => {
  return size;
  /*if (pixelRatio >= 2 && pixelRatio < 3) {
    // iphone 5s and older Androids
    if (deviceWidth < 360) {
      return size * 0.95;
    }
    // iphone 5
    if (deviceHeight < 667) {
      return size;
      // iphone 6-6s
    }
    if (deviceHeight >= 667 && deviceHeight <= 735) {
      return size * 1.15;
    }
    // older phablets
    return size * 1.25;
  }
  if (pixelRatio >= 3 && pixelRatio < 3.5) {
    // catch Android font scaling on small machines
    // where pixel ratio / font scale ratio => 3:3
    if (deviceWidth <= 360) {
      return size;
    }
    // Catch other weird android width sizings
    if (deviceHeight < 667) {
      return size * 1.15;
      // catch in-between size Androids and scale font up
      // a tad but not too much
    }
    if (deviceHeight >= 667 && deviceHeight <= 735) {
      return size * 1.2;
    }
    // catch larger devices
    // ie iphone 6s plus / 7 plus / mi note 等等
    return size * 1.27;
  }
  if (pixelRatio >= 3.5) {
    // catch Android font scaling on small machines
    // where pixel ratio / font scale ratio => 3:3
    if (deviceWidth <= 360) {
      return size;
      // Catch other smaller android height sizings
    }
    if (deviceHeight < 667) {
      return size * 1.2;
      // catch in-between size Androids and scale font up
      // a tad but not too much
    }
    if (deviceHeight >= 667 && deviceHeight <= 735) {
      return size * 1.25;
    }
    // catch larger phablet devices
    return size * 1.4;
  }
  return size;*/
};

export const FontSizes = {
  fontExtraSmall: adjustFontSize(8),
  fontLightSmall: adjustFontSize(10),
  fontSmall: adjustFontSize(12),
  fontNormal: adjustFontSize(14),
  fontExtraNormal: adjustFontSize(16),
  fontBig: adjustFontSize(24),
  fontMax: adjustFontSize(20),
  fontLightMax: adjustFontSize(32),
  fontUltraMax: adjustFontSize(34),
};
