const config = {
  getInstance: (() => {
    let _singleton;
    let _configuration;
    let _subscriptors = [];

    return () => {
      if (!_singleton) {
        _singleton = {
          getConfiguration: () => {
            return _configuration;
          },
          setConfiguration: (newConfiguration) => {
            _configuration = {..._configuration, ...newConfiguration};
          },
          addSubscriptor: (subscriptor) => {
            _subscriptors.push(subscriptor);
          },
          alertSubscriptor: payload => {
            _subscriptors.forEach(subscriptor => {
              subscriptor(payload);
            })
          },
          removeSubscriptors: () => {
            _subscriptors = [];
          },
        };
      }

      return _singleton;
    };
  })(),
};

export default config;
