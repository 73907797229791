import React, {createContext, useCallback, useContext, useMemo} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import Config from '../config';

import {SESSION_KEY, SESSION_DEFAULT_VALUE} from './state';

import {isExpirySession} from '../utils';

const session = (useStorage) => {
  const SessionContext = createContext();
  const {Provider} = SessionContext;

  const SessionProvider = (props) => {
    const [session, updateSession, hydrated] = useStorage(
      SESSION_KEY,
      SESSION_DEFAULT_VALUE,
    );

    const value = useMemo(
      () => ({
        session: session || SESSION_DEFAULT_VALUE,
        updateSession,
        sessionIsReady: hydrated,
      }),
      [session, updateSession, hydrated],
    );

    return <Provider value={value} {...props} />;
  };

  SessionProvider.propTypes = {
    children: PropTypes.shape({}).isRequired,
  };

  const useSession = () => {
    const context = useContext(SessionContext);
    const {useUser, sessionTime} = Config.getInstance().getConfiguration();
    const {user} = useUser();

    if (!context) {
      throw new Error('useSession must be used within a SessionProvider');
    }

    const {session, updateSession: update, ...rest} = context;

    const activeSession = useCallback(() => {
      if (user) {
        update({
          expirySession: user && user.is_pin ? moment().add(sessionTime, 'm') : null,
          expiry: user ? moment().add(sessionTime, 'm') : null,
        });
      }
    }, [user]);

    const isSessionExpiry = useMemo(() => {
      return isExpirySession(session.expiry);
    }, [session]);

    const logout = useCallback(() => {
      if (user) {
        update({
          expirySession: user && user.is_pin ? moment().diff(10, 'hours') : null,
          expiry: user ? moment().diff(10, 'hours') : null,
        });
      }
    }, [user]);

    const sessionClean = () => {
      update(SESSION_DEFAULT_VALUE);
    };

    return {
      ...rest,
      session,
      isSessionExpiry,
      activeSession,
      sessionClean,
      logout,
    };
  };

  return {
    SessionProvider,
    useSession,
  };
};

export default session;
