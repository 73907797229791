import { Assets } from "../../../resources";
import {capitalize} from '../../../util';

export const prepareIconServices = (name) => {
  let icon = Assets.images.cOther;
  name = name ? name.toUpperCase() : '';
  if (name.includes('GAS')) {
    icon = Assets.images.cGas;
  } else if (name.includes('INTERNET')) {
    icon = Assets.images.cInternet;
  } else if (name.includes('ELECTRICA')) {
    icon = Assets.images.cLight;
  } else if (name.includes('NETFLIX')) {
    icon = Assets.images.cNetflix;
  } else if (name.includes('TELEFONIA')) {
    icon = Assets.images.cPhone;
  } else if (name.includes('PLAYSTATION')) {
    icon = Assets.images.cPs;
  } else if (name.includes('TV') || name.includes('TELEVISION')) {
    icon = Assets.images.cTv;
  } else if (name.includes('AGUA')) {
    icon = Assets.images.cWater;
  } else if (name.includes('XBOX')) {
    icon = Assets.images.cXbox;
  }
  return icon;
};

export const prepareServicesOptions = (data) => {
  const preparedData = data.map((item) => {
    return {
      productType: item.attributes.product_type,
      // type: item.attributes.utility_type,
      label: item.attributes.utility_name,
      value: item.attributes.utility_number,
      icon: prepareIconServices(item.attributes.utility_type),
      backgroundIcon: Assets.images.cBgService,
      type: item.attributes.utility_argument_type,
      id: item.id,
    }
  })
  return preparedData;
};

export const prepareLastServicesPaid = (data) => {
  const preparedData = data.map((item) => {
    return {
      client_number: item.attributes.client_number,
      utility_name: item.attributes.utility_name,
      utility_number: item.attributes.utility_number,
      icon: prepareIconServices(item.attributes.utility_type),
      backgroundIcon: Assets.images.cBgService,
      id: item.id,
    }
  })
  return preparedData;
};

export const prepareListBills = (data, prepareAmount = () => { }) => {
  const preparedBills = data.map((item) => {
    const pay_validation = item.attributes.pay_validation ? item.attributes.pay_validation.trim() : '0';
    const order = item.attributes.order;

    return {
      amountLabel: prepareAmount(item.attributes.amount),
      amount: parseFloat(item.attributes.amount),
      client_number: item.attributes.client_number,
      id: item.attributes.id,
      order,
      pay_validation,
      utility_name: item.attributes.utility_name,
      utility_type: item.attributes.utility_type,
      icon: prepareIconServices(item.attributes.utility_type),
      backgroundIcon: Assets.images.cBgService,
      to_pay: pay_validation === '6' ? false : true,
      desactive: false,
      invoice_number: item.attributes.invoice_number,
      due_date: item.attributes.due_date,
      field_01: item.attributes.field_01,
    }
  });
  return preparedBills;
};

export const prepareMessageValidation = (data) => {
  let message = '';
  
  switch (data.pay_validation) {
    case "1":
      message = 'Sólo puedes pagar la primera factura de la lista.';
      break;
    case "3":
      message = 'Puedes pagar algunas facturas, pero en orden secuencial (de arriba hacia abajo).';
      break;
    case "5":
      message = 'Puedes pagar las facturas que desees, en el orden que desees.';
      break;
    case "6":
      message = 'Sólo puedes pagar una factura de la lista.';
      break;
    default:
      message = '';
      break;
  }

  return message;
}

export const resolveShowBillHelp = ({invoice_number, due_date, field_01, client_number}) => {
  let message = `Nro de cliente ${client_number}`;

  if(due_date) {
    return `Vcto. ${due_date}`;
  } else if (invoice_number) {
    return `Ref. ${invoice_number}`;
  } else if (field_01) {
    return capitalize(field_01);
  }

  return message;
}