import { useCallback, useEffect, useState } from 'react';
import useEncrypt from './useEncrypt';

import {BLACK_LIST_CONTEXT, log} from '../utils';
const useStorageCreator = (storage, params = {}) => {
  const { decrypt, encrypt } = useEncrypt(params);

  const useStorage = (key, defaultValue) => {
    const [state, setState] = useState({
      hydrated: false,
      storageValue: defaultValue,
    });
    const { hydrated, storageValue } = state;

    const getStorageValue = useCallback(async () => {
      let value = defaultValue;
      let fromStorage = null;

      if (BLACK_LIST_CONTEXT.includes(key)) {
        return state
      } else {
        try {
          fromStorage = await storage.getItem(key);
        } catch (e) {
          log('getStorageValue', e);
        } finally {
          if (fromStorage) {
            value = JSON.parse(decrypt(fromStorage));
          }
          setState({
            hydrated: true,
            storageValue: value,
          });

          return value;
        }
      }
    }, [key, defaultValue]);

    const updateStorage = useCallback(
      async (newValue) => {
        if (BLACK_LIST_CONTEXT.includes(key)) {
          return setState({
            hydrated: true,
            storageValue: newValue,
          });
        } else {
          try {
            if (newValue === null) {
              await storage.removeItem(key);
              setState({
                hydrated: true,
                storageValue: defaultValue,
              });
            } else {
              const stringifiedValue = JSON.stringify(newValue);
              await storage.setItem(key, encrypt(stringifiedValue));
              await getStorageValue();
            }
          } catch (e) {
            log('updateStorage', e);
          }
        }
      },
      [defaultValue, getStorageValue, key],
    );

    useEffect(() => {
      getStorageValue();
    }, [getStorageValue]);

    return [storageValue, updateStorage, hydrated, getStorageValue];
  };

  return useStorage;
};

export default useStorageCreator;
