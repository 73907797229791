import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from 'react-native';

import useWindow from 'wallet/hooks/useWindow';
import { FontSizes, Colors } from 'wallet/resources';

import styles from './styles';

export const EXTRA_SMALL_SIZE = 'fontExtraSmall'; // size: 8
export const LIGHT_SMALL_SIZE = 'fontLightSmall'; // size: 10
export const SMALL_SIZE = 'fontSmall'; // size: 12
export const NORMAL_SIZE = 'fontNormal'; // size: 14
export const EXTRA_NORMAL_SIZE = 'fontExtraNormal'; // size: 16
export const MED_SIZE = 'fontMed'; // size: 18
export const MAX_SIZE = 'fontMax'; // size: 20
export const EXTRA_MAX_SIZE = 'fontExtraMax'; // size: 22
export const BIG_SIZE = 'fontBig'; // size: 24
export const LIGHT_MAX_SIZE = 'fontLightMax'; // size: 32
export const ULTRA_MAX_SIZE = 'fontUltraMax'; // size: 34

const TextField = (props) => {
  const {
    isEllipsizeMode,
    style,
    underline,
    type,
    isTitle,
    isSecureData,
    isMediumFont,
    onPress,
    isPress,
    onLayout,
    numberOfLines,
    isCenter,
    isRight,
    isItalic,
  } = props;

  const { isAngie } = useWindow();

  const preparedStyle = useMemo(() => {
    if (typeof style === 'number') {
      return StyleSheet.flatten([style]);
    } else {
      return style;
    }
  }, [style]);

  let textProps = {
    style: [
      styles.font,
      underline ? styles.underline : styles.text,
      { fontSize: FontSizes[type] },
      isTitle ? styles.title : {},
      isMediumFont ? styles.medium_font : {},
      isCenter ? { textAlign: 'center' } : {},
      isRight ? { textAlign: 'right' } : {},
      isItalic ? { fontStyle: 'italic', paddingHorizontal: 2 } : {},
      style,
    ],
    onLayout,
  };

  if (isEllipsizeMode) {
    textProps.ellipsizeMode = 'tail';
    textProps.numberOfLines = 1;
  }

  if (isPress) {
    textProps.onPress = onPress;
  }

  if (isAngie()) {
    if (!isPress) {
      if (underline) {
        return (
          <View
            style={{
              borderBottomColor: preparedStyle.color ? preparedStyle.color : Colors.C_FFFFFF,
              borderBottomWidth: 1,
            }}>
            <Text numberOfLines={numberOfLines} {...textProps}>{props.text}</Text>
          </View>
        );
      }

      return <Text numberOfLines={numberOfLines} {...textProps}>{props.text}</Text>;
    }

    if (underline) {
      const child = (
        <View
          style={{
            borderBottomColor: preparedStyle.color ? preparedStyle.color : Colors.C_FFFFFF,
            borderBottomWidth: 1,
          }}>
          <Text numberOfLines={numberOfLines} {...textProps}>{props.text}</Text>
        </View>
      );

      return React.createElement('div', { onClick: onPress, style: { width: 'auto' } }, child);
    }

    const child = (
      <Text numberOfLines={numberOfLines} {...textProps}>
        {props.text}
      </Text>
    );

    return React.createElement('div', { onClick: onPress, style: { width: 'auto' } }, child);
  } else {
    if (underline) {
      return (
        <View
          style={{
            borderBottomColor: preparedStyle.color ? preparedStyle.color : Colors.C_FFFFFF,
            borderBottomWidth: 1,
          }}>
          <Text numberOfLines={numberOfLines} {...textProps}>{props.text}</Text>
        </View>
      );
    }

    return (
      <Text numberOfLines={numberOfLines} {...textProps}>
        {props.text}
      </Text>
    );
  }
};

TextField.propTypes = {
  isTitle: PropTypes.bool,
  text: PropTypes.any,
  style: PropTypes.any,
  type: PropTypes.string,
  underline: PropTypes.bool,
  isEllipsizeMode: PropTypes.bool,
  onPress: PropTypes.func,
  isPress: PropTypes.bool,
  isMediumFont: PropTypes.bool,
  onLayout: PropTypes.func,
  isSecureData: PropTypes.bool,
  isCenter: PropTypes.bool,
  isRight: PropTypes.bool,
  numberOfLines: PropTypes.number,
  isItalic: PropTypes.bool,
};

TextField.defaultProps = {
  isTitle: false,
  text: '',
  style: {},
  type: NORMAL_SIZE,
  underline: false,
  isEllipsizeMode: false,
  onPress: () => { },
  isPress: false,
  isMediumFont: false,
  onLayout: () => { },
  isSecureData: false,
  isCenter: false,
  isRight: false,
  isItalic: false,
};

export default TextField;
