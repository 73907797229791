import { ENDPOINTS } from '../../config/constants';
import { apiCall } from '../../middlewares';

const emailVerfication = (data) =>{
    return apiCall ({
        ...ENDPOINTS.email.validationEMailRegistration,
        data
    })
}

const resendEmailVerfication = (data) => {
    return apiCall({
        ...ENDPOINTS.email.resendEmailVerification,
        data
    })
}

export default {
    emailVerfication,
    resendEmailVerfication
}