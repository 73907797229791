import Config from '../config';
import couponsService from '../services/coupons';

const useCoupons = () => {
  const {useUser} = Config.getInstance().getConfiguration();
  const {user} = useUser();

  const getCouponsUser = async (status) => {
    try {
      const response = await couponsService.getCouponsUser(
        user.headers,
        status,
      );
      return response;
    } catch (error) {
      return error;
    }
  };

  const searchCoupon = async (identifier) => {
    try {
      const response = await couponsService.searchCouponsUser(
        user.headers,
        identifier,
      );
      return response;
    } catch (error) {
      return error;
    }
  };

  const activeCoupon = async (data) => {
    try {
      const response = await couponsService.activeCouponUser(
        user.headers,
        data,
      );

      return response;
    } catch (error) {
      return error;
    }
  };

  const cancelCoupon = async (coupon_user_id) => {
    try {
      const response = await couponsService.cancelCouponUser(
        user.headers,
        coupon_user_id,
      );
      return response;
    } catch (error) {
      return error;
    }
  };

  const getCouponTransaction = async (data) => {
    try {
      const response = await couponsService.getCouponTransactionUser(
        user.headers,
        data,
      );
      return response;
    } catch (error) {
      return error;
    }
  };

  return {
    getCouponsUser,
    searchCoupon,
    activeCoupon,
    cancelCoupon,
    getCouponTransaction,
  };
};

export default useCoupons;
