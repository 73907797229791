import { useReducer, useCallback, useEffect, useMemo } from 'react';

import Config from '../config';
import transactionsService from '../services/transactions';
import useAmounts from './useAmounts';
import useResidenceConfig from './useResidenceConfig';
import { applyDecimals, decimalRound, formatMoney } from '../utils';
import usePrices from './usePrices';
import useDisableButton from 'wallet/hooks/useDisableButton';

const AMOUNT_CHANGE = 'useExchange/AMOUNT_CHANGE';
const PRICES_UPDATE = 'useExchange/PRICES_UPDATE';
const CURRENCY_CHANGE = 'useExchange/CURRENCY_CHANGE';
const CURRENCY_RECEIVE_CHANGE = 'useExchange/CURRENCY_RECEIVE_CHANGE';
const UPDATE_RULES = 'useExchange/UPDATE_RULES';
const INVERT_CURRENCIES = 'useExchange/INVERT_CURRENCIES';

const calculateRate = ({
  currency,
  prices,
  receiveCurrency,
}) => {
  let rate;
  currency = currency.toLowerCase();
  receiveCurrency = receiveCurrency.toLowerCase();


  rate = prices[currency] ? prices[currency][`${receiveCurrency}_sell`] : 1;

  if (rate < 1) {
    rate = `1 ${receiveCurrency.toUpperCase()} = ${formatMoney(1 / rate, currency, currency.includes('usd') ? 4 : 2)} ${currency.toUpperCase()}`;
  } else {
    rate = `1 ${currency.toUpperCase()} = ${formatMoney(rate, receiveCurrency, receiveCurrency.includes('usd') ? 4 : 2)} ${receiveCurrency.toUpperCase()}`;
  }

  return rate;
};

const calculateMinimunAmount = ({
  min_amount_exchange,
  currency,
  default_currency,
  prices,
}) => {
  currency = currency?.toLowerCase();
  default_currency = default_currency?.toLowerCase();

  const minAmount = currency === default_currency
    ? min_amount_exchange
    : min_amount_exchange * prices[default_currency][`${currency}_sell`];

  return minAmount <= 0 ? 1 : minAmount;
};

const reducer = (state, action) => {
  switch (action.type) {
    case AMOUNT_CHANGE: {
      const {
        sendCurrencyDecimals,
        receiveCurrencyDecimals,
        min_amount_exchange,
        prices,
        default_currency,
      } = state;
      let {
        currency,
        receiveCurrency,
      } = state;
      const {
        value,
        isReceive,
        balances,
      } = action.payload;
      currency = currency.toLowerCase();
      receiveCurrency = receiveCurrency.toLowerCase();

      let amount = 0;
      let receiveAmount = 0;

      if (isReceive) {
        amount = value / prices[currency][`${receiveCurrency}_sell`];
        receiveAmount = value;
        amount = decimalRound(amount, 9);
      } else {
        amount = value;
        receiveAmount = value * prices[currency][`${receiveCurrency}_sell`];
      }

      const amountLabel = formatMoney(amount, currency, sendCurrencyDecimals);
      const receiveAmountLabel = formatMoney(
        receiveAmount,
        receiveCurrency,
        receiveCurrencyDecimals,
      );

      let minimumAmount = calculateMinimunAmount({
        currency,
        default_currency,
        min_amount_exchange,
        prices,
      });

      minimumAmount = applyDecimals(minimumAmount, currency, sendCurrencyDecimals);

      return {
        ...state,
        amount,
        amountLabel,
        receiveAmount,
        receiveAmountLabel,
        minimumAmount,
        isValid: amount >= minimumAmount && amount <= balances[currency.toLowerCase()],
      };
    }
    case CURRENCY_CHANGE: {
      const {
        currency,
        sendCurrencyDecimals,
        receiveCurrencies,
      } = action.payload;
      const {
        prices,
        min_amount_exchange,
        countryByCurrencyCode,
        default_currency,
        handle_default_fiat_currency,
        handle_default_crypto_currency,
      } = state;
      const currencyConfig = countryByCurrencyCode(currency);
      const receiveCurrency = currencyConfig.is_crypto
        ? handle_default_fiat_currency
        : handle_default_crypto_currency;
      const { currency_decimals: receiveCurrencyDecimals } = countryByCurrencyCode(receiveCurrency);

      let minimumAmount = calculateMinimunAmount({
        currency,
        default_currency,
        min_amount_exchange,
        prices,
      });

      minimumAmount = applyDecimals(minimumAmount, currency, sendCurrencyDecimals);

      return {
        ...state,
        currency,
        minimumAmount,
        receiveCurrency,
        receiveAmount: 0,
        receiveAmountLabel: formatMoney(0, receiveCurrency, receiveCurrencyDecimals),
        receiveCurrencyDecimals,
        amount: 0,
        amountLabel: formatMoney(0, currency, sendCurrencyDecimals),
        isValid: false,
        sendCurrencyDecimals,
        receiveCurrencies,
        rate: calculateRate({ currency, prices, receiveCurrency }),
      };
    }
    case CURRENCY_RECEIVE_CHANGE: {
      const {
        receiveCurrency,
        receiveCurrencyDecimals,
      } = action.payload;
      const {
        prices,
        currency,
      } = state;

      return {
        ...state,
        amount: 0,
        receiveCurrency,
        receiveAmount: 0,
        receiveAmountLabel: formatMoney(0, receiveCurrency, receiveCurrencyDecimals),
        isValid: false,
        receiveCurrencyDecimals,
        rate: calculateRate({ currency, prices, receiveCurrency }),
      };
    }
    case PRICES_UPDATE: {
      const {
        prices,
        errorFetch,
      } = action.payload;
      const {
        currency,
        receiveCurrency,
        min_amount_exchange,
        default_currency,
        sendCurrencyDecimals,
      } = state;

      let minimumAmount = calculateMinimunAmount({
        currency,
        default_currency,
        min_amount_exchange,
        prices,
      });

      minimumAmount = applyDecimals(minimumAmount, currency, sendCurrencyDecimals);

      return {
        ...state,
        prices,
        minimumAmount,
        rate: calculateRate({ currency, prices, receiveCurrency }),
        errorFetch: errorFetch === 'Error 429'
          ? 'Calculando precios'
          : errorFetch,
      };
    }
    case UPDATE_RULES: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case INVERT_CURRENCIES: {
      const {
        currency,
        receiveCurrency,
        sendCurrencyDecimals,
        receiveCurrencyDecimals,
      } = action.payload;
      const {
        default_currency,
        prices,
        min_amount_exchange,
      } = state;

      let minimumAmount = calculateMinimunAmount({
        currency,
        default_currency,
        min_amount_exchange,
        prices,
      });

      return {
        ...state,
        ...action.payload,
        minimumAmount,
        isValid: false,
        receiveAmount: 0,
        receiveAmountLabel: formatMoney(0, receiveCurrency, receiveCurrencyDecimals),
        amount: 0,
        amountLabel: formatMoney(0, currency, sendCurrencyDecimals),
        rate: calculateRate({ currency, prices, receiveCurrency }),
      }
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const useExchange = () => {
  const { useUser, useRules } = Config.getInstance().getConfiguration();
  const { prices, isReadyPrices, errorFetch } = usePrices();
  const { countryByCurrencyCode } = useAmounts();
  const { min_amount_exchange } = useResidenceConfig();
  const { cryptoAvailable, fiatCurrencies } = useRules();
  const { user, updateProfile, isActivableBalance } = useUser();
  const {
    default_currency,
    balances,
    handle_default_fiat_currency,
    handle_default_crypto_currency,
  } = user;
  const { currency_decimals } = countryByCurrencyCode(handle_default_fiat_currency) ? countryByCurrencyCode(handle_default_fiat_currency) : {};
  const { currency_decimals: crypto_decimals } = countryByCurrencyCode(handle_default_crypto_currency) ? countryByCurrencyCode(handle_default_crypto_currency) : {};

  const availableCurrencies = useMemo(() => ([...fiatCurrencies, ...cryptoAvailable]), [fiatCurrencies, cryptoAvailable]);

  const [state, dispatch] = useReducer(reducer, {
    amount: 0,
    receiveAmount: 0,
    amountLabel: formatMoney(0, handle_default_fiat_currency, currency_decimals),
    receiveAmountLabel: formatMoney(0, handle_default_crypto_currency, crypto_decimals),
    currency: handle_default_fiat_currency.toUpperCase(),
    sendCurrencyDecimals: currency_decimals,
    minimumAmount: min_amount_exchange,
    min_amount_exchange,
    receiveCurrency: handle_default_crypto_currency,
    receiveCurrencyDecimals: crypto_decimals,
    isValid: false,
    sendCurrencies: availableCurrencies,
    receiveCurrencies: cryptoAvailable,
    countryByCurrencyCode,
    default_currency,
    handle_default_fiat_currency,
    handle_default_crypto_currency,
    balances,
    rate: 1,
    prices,
    errorFetch: null,
  });

  const { isClicked, handleClickedDisableButton } = useDisableButton();

  const amountCalculator = useCallback(
    (value, isReceive = false) => {
      dispatch({
        type: AMOUNT_CHANGE,
        payload: {
          value,
          isReceive,
          balances,
        },
      });
    },
    [balances],
  );

  const changeAmount = useCallback(
    (value) => {
      amountCalculator(value);
    },
    [amountCalculator],
  );

  const useMaximum = useCallback(() => {
    changeAmount(balances[state.currency.toLowerCase()]);
  }, [balances, state, changeAmount]);

  const changeReceiveAmount = (value) => {
    amountCalculator(value, true);
  };

  const currenciesAvailables = useCallback((is_crypto, currency) => {
    let availables = [];

    if (is_crypto) {
      availables = availableCurrencies.filter(val => { return val !== currency.toUpperCase() })
    } else {
      availables = cryptoAvailable;
    }

    return availables;
  }, [availableCurrencies, cryptoAvailable]);

  const changeCurrency = useCallback(
    (currency) => {
      currency = currency.toUpperCase();
      const { currency_decimals, is_crypto } = countryByCurrencyCode(currency);

      dispatch({
        type: CURRENCY_CHANGE,
        payload: {
          currency,
          sendCurrencyDecimals: currency_decimals,
          receiveCurrencies: currenciesAvailables(is_crypto, currency),
        },
      });
    },
    [countryByCurrencyCode],
  );

  const changeReceiveCurrency = useCallback(
    (currency) => {
      currency = currency.toUpperCase();
      const { currency_decimals } = countryByCurrencyCode(currency);

      dispatch({
        type: CURRENCY_RECEIVE_CHANGE,
        payload: {
          receiveCurrency: currency,
          receiveCurrencyDecimals: currency_decimals,
        },
      });
    },
    [countryByCurrencyCode],
  );

  const isValid = useMemo(() => {
    return state.isValid && !errorFetch && isReadyPrices;
  }, [state, errorFetch, isReadyPrices]);

  useEffect(() => {
    if (!isReadyPrices) return () => { };

    dispatch({
      type: PRICES_UPDATE,
      payload: {
        prices,
        errorFetch,
      }
    });
    changeAmount(state.amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isReadyPrices,
    prices,
    errorFetch,
  ]);

  useEffect(() => {
    dispatch({
      type: UPDATE_RULES,
      payload: {
        sendCurrencies: availableCurrencies,
        receiveCurrencies: cryptoAvailable,
      }
    });
  }, [availableCurrencies]);

  const createExchange = useCallback(
    async (data) => {
      try {
        handleClickedDisableButton()
        await transactionsService.createExchange(user.headers, data);
        await updateProfile();
        return Promise.resolve({});
      } catch (error) {
        handleClickedDisableButton()
        return Promise.reject(JSON.stringify(error));
      }
    },
    [updateProfile, user.headers],
  );

  const onInvertCurrencies = useCallback(() => {
    const receiveCurrency = state.receiveCurrency.toUpperCase();
    const sendCurrency = state.currency.toUpperCase();

    const { currency_decimals: sendCurrencyDecimals, is_crypto } = countryByCurrencyCode(receiveCurrency);
    const { currency_decimals: receiveCurrencyDecimals } = countryByCurrencyCode(sendCurrency);

    dispatch({
      type: INVERT_CURRENCIES,
      payload: {
        currency: receiveCurrency,
        sendCurrencyDecimals,
        receiveCurrency: sendCurrency,
        receiveCurrencyDecimals,
        receiveCurrencies: currenciesAvailables(is_crypto, receiveCurrency),
      },
    });
  }, [state.currency, state.receiveCurrency]);

  return {
    ...state,
    isActivableBalance,
    isReadyPrices,
    errorFetch,
    changeAmount,
    changeReceiveAmount,
    changeCurrency,
    changeReceiveCurrency,
    onInvertCurrencies,
    createExchange,
    useMaximum,
    isValid,
    isDisabledButton: isClicked
  };
};

export default useExchange;
