import { useEffect } from 'react';

import Config from '../config';

const useUpdateBanks = () => {
  const { useUser, useBanks: useBanksContexts } = Config.getInstance().getConfiguration();
  const { user, userIsReady } = useUser();
  const {
    getProfileBanks,
  } = useBanksContexts();

  useEffect(() => {
    let isFetch = true;

    (async () => {
      try {
        if (userIsReady && user && isFetch) {
          await getProfileBanks(user);
          isFetch = false;
        }
      } catch (error) { }
    })();

    return () => {
      isFetch = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsReady, user]);

  return {};
};

export default useUpdateBanks;
