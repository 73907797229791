import { ENDPOINTS } from '../../config/constants';
import { apiCall } from '../../middlewares';

const getCountries = () => apiCall(ENDPOINTS.settings.countries);


const getFavoriteCountries = (headers) => {
  return apiCall({
    ...ENDPOINTS.settings.favorite_countries,
    headers,
  });
};

const getProvinces = (headers, country) => {
  const { url } = ENDPOINTS.settings.provinces;
  return apiCall({
    ...ENDPOINTS.settings.provinces,
    url: url.replace(':country', country),
    headers,
  });
};

const getCities = (headers, state) => (
  apiCall({
    ...ENDPOINTS.settings.cities(state),
    headers,
  })
);

const getLadaCodes = (headers, country) => {
  return apiCall({
    ...ENDPOINTS.settings.lada_codes(country),
    headers,
  });
};

// const getCities = (headers, state) => {
//   return apiCall({
//     ...ENDPOINTS.settings.states(state),
//     headers,
//   });
// };

const getCountryBanks = (headers, iso_code) =>
  apiCall({
    ...ENDPOINTS.settings.provinces,
    ...ENDPOINTS.settings.country_banks,
    data: { iso_code },
    headers,
  });

const getMobileVersions = () =>
  apiCall({
    ...ENDPOINTS.settings.get_mobile_versions,
  });

const getServerTime = () =>
  apiCall({
    ...ENDPOINTS.settings.get_server_time,
  });

const getMarketingMessages = (place) => {
  const { url } = ENDPOINTS.settings.get_messages;
  return apiCall({
    ...ENDPOINTS.settings.get_messages,
    url: url.replace(':place', place)
  });
};

const getInformationModalCountries = (headers, isoCode) => {

  return apiCall({
    ...ENDPOINTS.settings.get_modal_information_country(isoCode),
    headers,
  });
};

export default {
  getCountries,
  getProvinces,
  getCities,
  getCountryBanks,
  getMobileVersions,
  getServerTime,
  getMarketingMessages,
  getFavoriteCountries,
  getLadaCodes,
  getInformationModalCountries,
};
