import {DeviceUUID} from 'device-uuid';

const deviceName = () => {
  const du = new DeviceUUID().parse();

  return `${du.browser} ${du.os}`
}

export const getDeviceId = () => {
  const du = new DeviceUUID().parse();
  const info_id = [
    du.language,
    du.platform,
    du.os,
    du.cpuCores,
    du.isAuthoritative,
    du.silkAccelerated,
    du.isKindleFire,
    du.isDesktop,
    du.isMobile,
    du.isTablet,
    du.isWindows,
    du.isLinux,
    du.isLinux64,
    du.isMac,
    du.isiPad,
    du.isiPhone,
    du.isiPod,
    du.isSmartTV,
    du.pixelDepth,
    du.isTouchScreen
  ];

  return du.hashMD5(info_id.join(':'));
  // new DeviceUUID().get();
};

export const getUniqueId = () => {
  const du = new DeviceUUID().parse();
  const info_id = [
    du.language,
    du.platform,
    du.os,
    du.cpuCores,
    du.isAuthoritative,
    du.silkAccelerated,
    du.isKindleFire,
    du.isDesktop,
    du.isMobile,
    du.isTablet,
    du.isWindows,
    du.isLinux,
    du.isLinux64,
    du.isMac,
    du.isiPad,
    du.isiPhone,
    du.isiPod,
    du.isSmartTV,
    du.pixelDepth,
    du.isTouchScreen
  ];

  return du.hashMD5(info_id.join(':'));
  // new DeviceUUID().get();
};

// export const getDeviceId = () => 'd520c7a8-421b-4563-b955-f5abc56b97ed';
// export const getDeviceId = () => 'd520c7a8-421b-4563-b955-f5abc56b97ec';

export const getDeviceName = async () => await deviceName();

export const hasDynamicIsland = () => { return false };

export const hasNotch = () => { return false };