import {apiCall} from '../../middlewares';
import {ENDPOINTS} from '../../config/constants';

const getResidenceConfig = (headers) => {
  return apiCall({
    ...ENDPOINTS.settings.get_country_config,
    headers,
  });
};

export default {
  getResidenceConfig,
};
