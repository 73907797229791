import { ENDPOINTS } from '../../config/constants';
import { apiCall } from '../../middlewares';

const getBannersUser = (headers) => {
  return apiCall({
    ...ENDPOINTS.banners.getBanners,
    headers,
  });
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  getBannersUser,
};