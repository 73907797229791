import { useState, useEffect, useMemo, useCallback } from 'react';
import { Platform } from 'react-native';
import { paymentService } from '../services';
import config from '../config';

import { prepareListBills, prepareLastServicesPaid, prepareMessageValidation } from 'wallet/scenes/logged/services/util';

import useAmounts from '../hooks/useAmounts';
import { formatMoney } from '../utils';

const usePaymentServices = (props) => {
  const {
    startFetch,
    endFetch,
  } = props;
  const { useUser } = config.getInstance().getConfiguration();
  const { user, updateProfile } = useUser();
  const { countryByCurrencyCode } = useAmounts();
  const { balances, default_currency, status } = user;
  const { currency_decimals } = countryByCurrencyCode(default_currency);
  const [service, setService] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [searchByCategory, setSearchByCategory] = useState(null);
  const [clientNumber, setClientNumber] = useState('');
  const [validPayments, setValidPayments] = useState(false);
  const [messageValidation, setMessageValidation] = useState('');
  const [disableTimer, setDisableTimer] = useState(false);
  const [bills, setBills] = useState(null);
  const [active, setActive] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [lastPaid, setLastPaid] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);

  const changeSearchValue = useCallback((value) => {
    setSearchValue(value);
    setSearchByCategory(null);
  }, [setSearchValue]);

  const changeClientNumber = useCallback((number) => {
    setClientNumber(number);
  }, [setClientNumber]);

  const onPressDefaultCategory = useCallback(category => {
    setSearchValue(category.title);
    setSearchByCategory(category.id);
    onActive();
  }, []);

  const onDesactive = () => {
    setActive(false);
  };

  const onActive = () => {
    setActive(true);
  };

  const onSearchServicesByFilter = useCallback(async () => {
    try {
      startFetch();
      const response = await paymentService.getAvailableListServices(user.headers, {
        title: searchValue,
        category: searchByCategory
      });
      endFetch();

      return response;
    } catch (error) {
      endFetch(error);
      throw new Error(error);
    }
  }, [user, searchValue]);

  const onSelectService = useCallback((selected) => {
    setDisableTimer(true);
    setService(selected);
    setSearchValue(selected.label);
    setTimeout(() => { setDisableTimer(false) }, 1000);
  }, [setService, setSearchValue]);

  const amountPrepared = useCallback((amount) => {
    return formatMoney(amount, default_currency, currency_decimals);
  }, [default_currency, currency_decimals]);

  const onGetListBills = useCallback(async () => {
    try {
      startFetch();
      const response = await paymentService.getUserListBills(user.headers, {
        utility_number: service.value,
        account_reference: clientNumber
      });

      if (response.data) {
        const bills_aux = prepareListBills(response.data, amountPrepared);
        setBills(bills_aux);
        setMessageValidation(prepareMessageValidation(bills_aux[0]));
        setValidPayments(true);
      } else {
        setBills([]);
      }

      endFetch();
    } catch (error) {
      setBills(null);
      endFetch(error);
      // throw new Error(error);
    }

  }, [user, clientNumber, service]);

  const totalAmount = useMemo(() => {
    const total = bills
      ? bills.reduce((total, value) => {
        return total = total + (value.to_pay && !value.desactive ? value.amount : 0);
      }, 0)
      : 0;
    return total;
  }, [bills]);

  const totalAmountLabel = useMemo(() => {
    return formatMoney(totalAmount, default_currency, currency_decimals);
  }, [totalAmount]);

  const onSelectBill = useCallback((id) => {
    const toPay = bills.map((value) => {
      if (value.id === id) {
        value.to_pay = !value.to_pay;
      } else if (value.pay_validation === '6') {
        value.to_pay = false;
      }
      return value;
    })
    setValidPayments(true);
    setBills(toPay);
  }, [bills]);

  const isSearchBills = useMemo(() => {
    return bills ? true : false;
  }, [bills]);

  const onCancel = useCallback(() => {
    setBills(null);
    setService({});
    setClientNumber('');
    setSearchValue('');
    setIsConfirmation(false);
  }, []);

  const isAccountSearchDisabled = useMemo(() => {
    return (!service.value || clientNumber === '')
  }, [service, clientNumber]);

  const isSelectedService = useMemo(() => Object.keys(service).length > 0, [service]);

  const isValidForm = useMemo(() =>
    totalAmount > 0 &&
    service.value &&
    clientNumber !== '' &&
    totalAmount <= balances[default_currency.toLowerCase()]
    , [totalAmount]);

  const showPaymentConfirmation = useCallback(() => {
    setIsConfirmation(true);
  }, [
    setIsConfirmation,
  ]);

  const hiddenPaymentConfirmation = useCallback(() => {
    setIsConfirmation(false);
  }, [
    setIsConfirmation,
  ]);

  const createPaymentService = useCallback(async (cancel) => {
    try {
      startFetch();
      const toPay = bills.filter(bill => bill.to_pay === true);
      const billsIDs = toPay.map(bill => bill.id);
      const data = {
        service_list: billsIDs,
        source: Platform.OS
      };

      if (cancel) {
        data.cancel = true;
      }

      const response = await paymentService.createPaymentService(user.headers, data);

      endFetch();
      return response;
    } catch (error) {
      endFetch();
      return Promise.reject(error)
    }
  }, [
    bills,
  ]);

  useEffect(() => {
    const recursiveValidation = (i, aux = []) => {
      if (i === bills.length) {
        setValidPayments(false);
        setBills(aux);
        return false;
      }

      const current = bills[i];
      const prev = bills[i - 1];

      if (current.pay_validation === '1' && current.order !== 1) {
        current.desactive = true;
      } else if (current.pay_validation === '3' && prev && !prev.to_pay) {
        current.desactive = true;
        current.to_pay = false;
      } else if (current.pay_validation === '6' && i === 0 && bills.length === 1) {
        current.to_pay = true;
      } else {
        current.desactive = false;
      }

      aux.push(current);

      recursiveValidation(i + 1, aux);
    };

    if (bills && validPayments) {
      recursiveValidation(0);
    }

  }, [validPayments, bills]);

  const hiddenListServices = useMemo(() => lastPaid.length !== 0, [lastPaid]);

  const payLastPaidService = useCallback((service) => {
    setDisableTimer(true);
    setSearchValue(service.utility_name);
    setService({
      id: service.id,
      icon: service.icon,
      value: service.utility_number,
      label: service.utility_name,
      productType: '',
      type: '',
    });
    setClientNumber(service.client_number);
    setActive(true);
    setTimeout(() => { setDisableTimer(false) }, 1000);
  }, [
    setSearchValue,
    setService,
    setClientNumber,
    setActive,
  ]);

  useEffect(() => {
    (async () => {
      try {
        if (user.headers) {
          const response = await paymentService.getLastServicesPaid(user.headers);
          setLastPaid(prepareLastServicesPaid(response.data));
        }
      } catch { }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (user.headers) {
          const response = await paymentService.getServiceCategories(user.headers);
          setCategoriesList(response.data.map(item => ({
            id: item.id,
            icon: item.attributes.icon,
            title: item.attributes.title,
            segment: item.attributes.segment,
            isIconOnly: item.attributes.is_only_icon,
          })))
        }
      } catch { }
    })();
  }, []);

  return {
    messageValidation,
    searchByCategory,
    updateProfile,
    categoriesList,
    lastPaid,
    payLastPaidService,
    hiddenListServices,
    isValidForm,
    totalAmount,
    totalAmountLabel,
    balances,
    default_currency,
    active,
    service,
    searchValue,
    clientNumber,
    bills,
    showPaymentConfirmation,
    hiddenPaymentConfirmation,
    isConfirmation,
    isSearchBills,
    isAccountSearchDisabled,
    changeSearchValue,
    changeClientNumber,
    onPressDefaultCategory,
    onDesactive,
    onActive,
    onCancel,
    onSearchServicesByFilter,
    onSelectService,
    onGetListBills,
    onSelectBill,
    createPaymentService,
    disableTimer,
    isSelectedService,
    status,
  };
};

export default usePaymentServices;