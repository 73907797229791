import { apiCall } from '../../middlewares';
import { ENDPOINTS } from '../../config/constants';

const getPrices = (headers) => {
  return apiCall({
    ...ENDPOINTS.prices.get,
    headers,
  });
};

const getTransferPrices = (headers) => {
  return apiCall({
    ...ENDPOINTS.prices.getTransferPrices,
    headers,
  });
};

export default {
  getPrices,
  getTransferPrices,
};
