import React, {useState} from 'react';

const usePopUp = () => {
  const [settingsPopUp, setSettingsPopUp] = useState({
    isVisible: false,
    isFull: false,
    Children: <></>,
    isCancelButton: true,
    containerStyle: {},
    isCloseButton: true,
    HeaderComponent: null,
    isSociety: false,
    isShowClose: true,
    data: {},
  });

  return {
    settingsPopUp,
    showPopUp: (
      Children,
      isFullComponent = false,
      isCancelButton = true,
      containerStyle = {},
      isCloseButton = true,
      popUpStyle = null,
      HeaderComponent = null,
      isSociety = false,
      isShowClose = true,
      data = {},
    ) => {
      setSettingsPopUp({
        isVisible: true,
        Children,
        isFull: isFullComponent,
        isCancelButton,
        containerStyle,
        isCloseButton,
        HeaderComponent,
        isSociety,
        isShowClose,
        data,
      });
    },
    showFulPopUp: (
      Children, 
      HeaderComponent = null,
      isCancelButton = false,
      isCloseButton = false,
      isShowClose =  true,
      isContentTop = false,
      containerStyle = {},
    ) => {
      setSettingsPopUp({
        isVisible: true,
        Children,
        isFull: true,
        isCancelButton,
        containerStyle: [{padding: 0}, isContentTop && {justifyContent: 'flex-start'}, containerStyle],
        isCloseButton,
        HeaderComponent,
        isSociety: false,
        isShowClose
      });
    },
    hiddenPopUp: () => {
      setSettingsPopUp({
        isVisible: false,
        isFull: false,
        Children: <></>,
        isCancelButton: true,
        containerStyle: {},
        isCloseButton: true,
        HeaderComponent: null,
        isSociety: false,
        isShowClose: true
      });
    },
  };
};

export default usePopUp;
