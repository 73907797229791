import {hotjar} from 'react-hotjar';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import '../alejandra/config';
import Alejandra from '../alejandra';
import {HOTJAR_HJID, HOTJAR_HJSV, CIO_SITE_ID} from '../alejandra/config/constants';

import './index.css';
import './recharge.css';
import './transfer.css';
import './integration.css';
import './media_querys.scss';
import './sidebar.css';

if(window.prepareCustomerioForAngie) {
  window.prepareCustomerioForAngie(CIO_SITE_ID);
}

if(window.prepareIntercomForAngie) {
  window.prepareIntercomForAngie(window.innerWidth >= 919);
}

hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);

export default Alejandra;
