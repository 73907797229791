import React, { useRef, memo, useEffect, useState, Suspense, lazy } from 'react';
import analytics from '@react-native-firebase/analytics';
import messaging from '@react-native-firebase/messaging';
import {
  useSettings,
  useVersions,
  useUpdatePrices,
  useUpdateTransferPrices,
  useSession,
  useUpdateBanks,
  useUpdateUserBalances
} from 'dumdee';
import { NavigationContainer, useLinking } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import useCustomerio from 'wallet/hooks/useCustomerio';
import Loading from '../components/commons/Loading';
import { configurationDeepLinking, UTM_PARAMS } from './util';
import useFacebook from 'wallet/hooks/useFacebook';

const Stack = createStackNavigator();
const Screens = lazy(() => import('./navigators/screens'));

const CallVersions = () => {
  useVersions();
  return null;
}

const Prices = ({ route = '' }) => {
  useUpdatePrices(route);
  return null;
}

const TransferPrices = ({ route = '' }) => {
  useUpdateTransferPrices(route);
  return null;
}

const Banks = () => {
  useUpdateBanks();
  return null;
};

const Balances = ({ route = '' }) => {
  useUpdateUserBalances(route);
  return null;
};

const Session = memo(({ route }) => {
  const { activeSession, isSessionExpiry } = useSession();

  useEffect(() => {
    if (route && !isSessionExpiry) {
      (async () => { await activeSession() })();
    }

    return () => { }
  }, [route, isSessionExpiry]);

  return null;
}, (prev, next) => {
  return prev.route === next.route;
})

const AppNavigator = () => {
  const navigationRef = useRef();
  const routeNameRef = useRef();
  const { getInitialState } = useLinking(navigationRef, configurationDeepLinking);
  const [isReady, setIsReady] = useState(false);
  const [routeName, setRouteName] = useState('');
  const { addPushNotification } = useSettings();
  const { trackPages } = useCustomerio();
  const { pageViewEvent } = useFacebook();

  useEffect(() => {
    const unsubscribe = messaging().onMessage((m) => {
      addPushNotification(m);
    });

    return unsubscribe;
  }, [addPushNotification]);

  useEffect(() => {
    return (async () => {
      try {
        await Promise.race([
          getInitialState(),
          new Promise((resolve) => setTimeout(resolve, 150)),
        ]);

        setIsReady(true);

        return () => {
          setIsReady(false);
        }
      } catch (e) {
        return () => {
          setIsReady(false);
        }
      }
    })();
  }, [getInitialState]);

  useEffect(() => {
    if (window && window.sessionStorage && window.sessionStorage.getItem(UTM_PARAMS) === null) {
      window.sessionStorage.setItem(UTM_PARAMS, (window.location.search).replace(/^[?&]+/, ''));
    }
  }, []);

  if (!isReady) {
    return null;
  }

  const onReady = () => {
    routeNameRef.current = navigationRef.current.getCurrentRoute().name;
  };

  const onStateChange = async () => {
    const previousRouteName = routeNameRef.current;
    const currentRouteName = navigationRef.current.getCurrentRoute().name;

    if (previousRouteName !== currentRouteName) {
      setRouteName(currentRouteName);
      await trackPages(currentRouteName);
      await pageViewEvent();
      await analytics().logScreenView({
        screen_name: currentRouteName,
        screen_class: currentRouteName,
      });
    }

    routeNameRef.current = currentRouteName;
  };

  return (
    <>
      <CallVersions />
      <Banks />
      <Prices
        route={routeName}
      />
      <TransferPrices
        route={routeName}
      />
      <Balances
        route={routeName}
      />
      <Session
        route={routeName}
      />
      <NavigationContainer
        ref={navigationRef}
        onReady={onReady}
        onStateChange={onStateChange}>
        <Suspense fallback={<Loading />}>
          <Stack.Navigator>
            <Stack.Screen component={Screens} />
          </Stack.Navigator>
        </Suspense>
      </NavigationContainer>
    </>
  );
};

export default AppNavigator;
