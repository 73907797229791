import axios from 'axios';
import {Platform} from 'react-native';
import Instance from '../../config';

import {logOutForce} from '../../utils';

const appName = () => {
  if (Platform.OS !== 'web') {
    return 'ALEJANDRA';
  } else {
    return 'ANGIE';
  }
};

const apiCall = async (config, onError = logOutForce) => {
  try {
    config.headers = {
      ...config.headers,
      'app-name': appName(),
      'app-source': Platform.OS,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    };
    const response = await axios(config);
    let headers = {};
    if (response.headers) {
      if (response.headers['access-token-mobile']) {
        headers = {
          'access-token-mobile': response.headers['access-token-mobile'],
          'expire-token-mobile': response.headers['expire-token-mobile'],
          'x-device': response.headers['x-device'],
        };
      } else {
        headers = {
          'access-token': response.headers['access-token'],
          'x-device': response.headers['x-device'],
          uid: response.headers.uid,
          client: response.headers.client,
          expiry: response.headers.expiry,
        };
      }

      let headersPayload = {
        'x-settings': response.headers['x-settings'],
        'x-residence-config': response.headers['x-residence-config'],
        'x-rules': response.headers['x-rules'],
      };

      if (response.headers['x-favorite-accounts']) {
        headersPayload['x-favorite-accounts'] =
          response.headers['x-favorite-accounts'];
      }

      Instance.getInstance().alertSubscriptor(headersPayload);
    }

    return {
      ...response.data,
      headers,
    };
  } catch (error) {
    onError(error, config);

    let newError = {
      error: 'No pudimos conectarte al servidor. Intenta nuevamente',
    };
    let status = 500;

    if (error && error.response) {
      status = parseInt(error.response.status);
      if (error.response.data) {
        newError = error.response.data;
      }
    }

    throw {
      ...newError,
      status,
      config,
    };
  }
};

export default apiCall;
