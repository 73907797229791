import { ENDPOINTS } from '../../config/constants';
import { apiCall } from '../../middlewares';

const getLimitsUser = (headers) => {
  return apiCall({
    ...ENDPOINTS.profile.getLimitsUser,
    headers,
  });
};

const RequestUpdateLimits = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.profile.RequestUpdateLimits(data),
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
 });
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  getLimitsUser,
  RequestUpdateLimits
};