import {useState, useEffect} from 'react';
import settingsService from '../services/settings';

const useMessageMarketing = (placement = null) => {

  const defaultMessage = {
    "title_es": "Lo que siempre necesitaste para gestionar tu dinero:",
    "title_en": "What you always needed to manage your money:",
    "messages": [
        {
            "message_es": "Seguridad y verificación de usuarios con biometría facial.",
            "message_en": "Security and verification of users with facial biometrics."
        },
        {
            "message_es": "Bono de bienvenida para que realices tu primera transferencia.",
            "message_en": "Welcome bonus for you to make your first transfer."
        },
        {
            "message_es": "Retiros a tu cuenta bancaria en divisa local.",
            "message_en": "Withdrawals to your bank account in local currency."
        },
        {
            "message_es": "Compra de Bitcoin a la tasa más baja de todo Chile.",
            "message_en": "Buy Bitcoin at the rate lowest in all of Chile."
        }
    ]
  };

  const {getMarketingMessages} = settingsService;
  const [messages, setMessages] = useState(defaultMessage);

  const [place, setPlace] = useState(placement);

  const changePlace = val => {
    setPlace(val);
  }

  useEffect(() => {
    let isFetch = true;

    ( async () => {
      if(isFetch && place) {
        try {
          const response = await getMarketingMessages(place);
          setMessages(response.data.attributes);

          isFetch = false;
        } catch (error) {}
      }
    } )();

    return () => {
      isFetch = false;
    };
  }, [place]);

  return ({
    messages,
    changePlace
  });
};

export default useMessageMarketing;