const analytcs = () => {
  const GA_MEASUREMENT_ID = window.GA_MEASUREMENT_ID();

  const logEvent = async (name, params) => {
    try {
      window.gtag('event', name, params);
      return Promise.resolve('Success');
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const logSignUp = async (params) => {
    try {
      window.gtag('event', 'sign_up', params);
      return Promise.resolve('Success');
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const logLogin = async (params) => {
    try {
      window.gtag('event', 'login', params);
      return Promise.resolve('Success');
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const setUserId = async (id) => {
    try {
      window.gtag('config', GA_MEASUREMENT_ID, {
        user_id: id,
      });
      return Promise.resolve('Success');
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const logScreenView = async (payload) => {
    try {
      return Promise.resolve('Success');
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return {
    logEvent,
    logSignUp,
    logLogin,
    setUserId,
    logScreenView,
  };
};

export default analytcs;
