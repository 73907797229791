export const transformScreenNavigation = {
  Authentication: '/menu',
  SignInScene: '/register',
  LogInScene: '/login',
  Logged: '/dashboard',
  Dashboard: '/dashboard',
  Exchange: '/crypto',
  Recharge: '/recharge',
  Receive: '/receive',
  Sent: '/send',
  AccountBank: '/bank-accounts',
  Integration: '/vita-business-integration',
  Security: '/security',
  Notifications: '/notifications',
  PushNotifications: '/push-notifications',
  PersonalInformation: '/personal-information',
  CryptoSettings: '/crypto-configuration',
  AccountActivator: '/account-activator',
  Pin: '/pin',
  PaymentLink: '/payment-link',
  VerificationStatus: '/verification-status',
  Browser: '/browser',
  PaymentGateway: '/payment-gateway',
  PaymentVoucher: '/voucher',
  EmailConfirmation: '/email-confirmation',
  RecoverPinAccessScene: '/recover-pin',
  MenuScene: '/menu',
  RecoverPasswordAccessScene: '/recover-password',
  PinLogIn: '/pin-login',
  BusinessCredentials: '/business-credentials',
  LoadingScene: '/loading',
  Loading: '/login',
  AccountActivatorManual: '/account-activator-manual',
  Favorites: '/favorites',
  CreateOrEditFavorite: '/create-or-edit-favorite',
  CardRequest: '/card-request',
  VitaCard: '/vita-card',
  VitaCardCurrency: '/vita-card/currency',
  SetPin: '/set-pin',
  Coupons: "/coupons",
  ValidateSignScene: "/validate-sign",
  ActivateBalance: "/activate-balance",
  PaymentServices: "/payment-services",
  ChangeAvatar: '/change-avatar',
  PaymmentByLot: '/payment-by-lot',
  Limits: '/limits',
  Balances: '/balances',
  Checkout: '/checkout',
  BusinessPaymentLink: '/business-payment-link',
  PaymentOrderLink: '/payment-order-link',
  LostFund: '/help/lost_fund',
  UsdAccount: '/usd-account',
};
