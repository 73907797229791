import React from 'react';
import PropTypes from 'prop-types';
import useOnlineStatus from '@rehooks/online-status';

export const NetworkConsumer = (props) => {
  const {children} = props;
  const onlineStatus = useOnlineStatus();
  return <>{children({isConnected: onlineStatus})}</>;
};

NetworkConsumer.propTypes = {
  children: PropTypes.func,
};

NetworkConsumer.defaultProps = {
  children: () => <></>,
};

export const NetworkProvider = (props) => {
  const {children} = props;
  return <>{children}</>;
};

NetworkProvider.propTypes = {
  children: PropTypes.object,
};

NetworkProvider.defaultProps = {
  children: <></>,
};
