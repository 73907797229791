import {useState, useEffect, useCallback} from 'react';

import Config from '../config';
import userService from '../services/user';

const useAccountBankAffiliation = () => {
  const [account_bank, setAccountBank] = useState('');
  const [account_type, setAccountType] = useState('');
  const [account_type_key, setAccountTypeKey] = useState('');
  const [bank, setBank] = useState('');
  const [bank_key, setBankKey] = useState('');
  const [accountTypes, setAccountTypes] = useState([]);

  const {useUser, useBanks} = Config.getInstance().getConfiguration();
  const {user, updateProfile} = useUser();
  const {
    banks, 
    // getProfileBanks,
  } = useBanks();
  const {banksProfile} = banks;

  // useEffect(() => {
  //   let isFetch = true;

  //   (async () => {
  //     try {
  //       if (isFetch) {
  //         await getProfileBanks(user);
  //         isFetch = false;
  //       }
  //     } catch (error) {}
  //   })();

  //   return () => {
  //     isFetch = false;
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);

  useEffect(() => {
    banksProfile.banks.forEach((item) => {
      const {value, account_types} = item;
      if (value === bank_key) {
        setAccountTypes(account_types);
      }
    });
  }, [bank_key, banksProfile]);

  const accountBankChange = (value) => {
    setAccountBank(value);
  };

  const saveBankAccount = useCallback(async () => {
    try {
      await userService.saveProfileBank(user.headers, {
        bank,
        type_account_bank: account_type_key,
        account_bank,
      });

      await updateProfile();
      return Promise.resolve('');
    } catch (error) {
      return Promise.reject(error);
    }
  }, [user, bank, account_type_key, account_bank, updateProfile]);

  return {
    account_bank,
    account_type,
    account_type_key,
    bank,
    accountTypes,
    bank_key,
    setBankKey,
    setBank,
    setAccountTypeKey,
    setAccountType,
    banks: banksProfile.banks,
    accountBankChange,
    saveBankAccount,
  };
};

export default useAccountBankAffiliation;
