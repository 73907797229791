import React, { createContext, useCallback, useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import useResidenceConfig from '../hooks/useResidenceConfig';

import { VERSIONS_KEY, VERSIONS_DEFAULT_VALUE } from './state';
import config from '../config';

const HEADER_RULES = 'x-rules';
const HEADER_RESIDENCE_CONFIG = 'x-residence-config';
const HEADER_FAVORITE_ACCOUNTS = 'x-favorite-accounts';

const versions = (useStorage) => {

  const VersionsContext = createContext();
  const { Provider } = VersionsContext;

  let _isFetch = false;
  let _isUpgradeable = false;

  const VersionsProvider = (props) => {

    const [versions, updateVersions, hydrated, getVersions] = useStorage(
      VERSIONS_KEY,
      VERSIONS_DEFAULT_VALUE,
    );

    const value = useMemo(
      () => ({
        versions: versions || VERSIONS_DEFAULT_VALUE,
        updateVersions,
        getVersions,
        versionsIsReady: hydrated,
      }),
      [versions, updateVersions, hydrated, getVersions]
    );
    return <Provider value={value} {...props} />;
  }

  VersionsProvider.propTypes = {
    children: PropTypes.shape({}).isRequired,
  };

  const useVersionsContext = () => {
    const { useUser, useRules, useFrequentAccounts } = config.getInstance().getConfiguration();
    const context = useContext(VersionsContext);
    const { user } = useUser();
    const { setResidenceConfig } = useResidenceConfig();
    const { setTransferRules } = useRules();
    // const { updateFavoriteAccounts } = useFrequentAccounts();

    if (!context) {
      throw new Error('useVersions must be used within a VersionsProvider');
    }

    const { versions, updateVersions, versionsIsReady, getVersions, ...rest } = context;

    const updateResidenceConfig = async () => {
      try {
        await setResidenceConfig(true);
      } catch { }
    };

    const updateRules = useCallback(async () => {
      try {
        await setTransferRules(user);
      } catch { }
    }, [user]);

    // const updateFavAccounts = useCallback(async () => {
    //   try {
    //     await updateFavoriteAccounts(user);
    //   } catch { }
    // }, [user]);

    const onCheckHeaders = useCallback(headers => {
      if (versionsIsReady && !_isFetch && user) {
        _isFetch = true;

        getVersions().then(versions => {
          if (parseInt(headers[HEADER_RULES], 10) > parseInt(versions[HEADER_RULES], 10)) {
            try {
              updateRules();
              _isUpgradeable = true;
            } catch { }
          }

          if (parseInt(headers[HEADER_RESIDENCE_CONFIG], 10) > parseInt(versions[HEADER_RESIDENCE_CONFIG], 10)) {
            try {
              updateResidenceConfig();
              _isUpgradeable = true;
            } catch { }
          }

          // if (parseInt(headers[HEADER_FAVORITE_ACCOUNTS], 10) > parseInt(versions[HEADER_FAVORITE_ACCOUNTS], 10)) {
          //   try {
          //     updateFavAccounts();
          //     _isUpgradeable = true;
          //   } catch { }
          // }

          if (_isUpgradeable) {
            _isUpgradeable = false;

            updateVersions({
              ...headers,
            });
          }

          _isFetch = false
        })
      }

    }, [user, versions, updateVersions, versionsIsReady, _isFetch, _isUpgradeable]);

    useEffect(() => {
      config.getInstance().addSubscriptor(onCheckHeaders);
    }, []);

    const cleanVersions = useCallback(() => {
      updateVersions({
        ...VERSIONS_DEFAULT_VALUE,
      });
    }, [updateVersions]);

    if (!user) {
      config.getInstance().removeSubscriptors();
    }

    return {
      ...rest,
      versions,
      updateVersions,
      cleanVersions
    }
  }

  return {
    VersionsProvider,
    useVersionsContext
  };
};

export default versions;
